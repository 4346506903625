<template>
  <div 
    class="progress" 
    :style="'height:'+options.height+'px'" 
    v-if="status">
    <div 
      :style="'background-color:'+options.color+';'" 
      class="progress-bar progress-bar progress-bar-striped loader" 
      role="progressbar" 
      aria-valuenow="0" 
      aria-valuemin="0" 
      aria-valuemax="100"></div>
  </div>
</template>
<script>
export default {
  name: "ProgressBar",
  props: {
    status: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default() {
        return { height: 4, color: "#007BFF" };
      }
    }
  }
};
</script>

<style scoped>
.loader {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
</style>
