<template>
  <div class="container-fluid p-0">
    <div class="row p-0">
      <div class="col">
        <h4>Payslip</h4>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserProfilePayslip"
};
</script>

<style>
</style>
