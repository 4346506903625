<script>
  export default {
    name: "GroupUsers",
    props: {
      group: {
        default: null,
        required: true,
        type: Object
      }
    },
    data() {
      return {
        selectedUser: [],
        filter: {
          total: 0,
          per_page: 24,
          from: 1,
          to: 0,
          current_page: 1,
          data: [],
          term: "",
          status: "1",
          department: "Department"
        },
        progress: false
      }
    },
    computed: {
      countSelected() {
        return this.selectedUser.length
      }
    },
    mounted() {
     this.get(this.filter.current_page, this.filter.per_page, this.filter.term);
    },
    methods: {
      get(current_page, per_page, term) {
        let __this = this;
        __this.progress = true;
        __m
          ._http(
            "post",
            "va?page=" + current_page + "&per_page=" + per_page + "&q=" + term
          )
          .then(e => {
            __this.filter = e.data;
            __this.filter.status = 1;
            __this.filter.department = "department";
            __this.progress = false;
          })
          .catch(e => {
            alert(e);
            __this.progress = false;
          });
      },
      attachToGroup()
      {
        __m._http("post", "permission/attach/"+this.group.id , this.selectedUser).then(() => {
         window.location.reload();
          alert('New employee added to group');
        })
      },
      closeGroupUsers(){
        this.$emit("clear-selected-group", 0);
      }
    },
  };
</script>

<template>
  <div class="card border-0 shadow mb-3">
    
    <div class="card-body">
      <h5> {{ group.name }}
        <button
          class="btn bg-transparent text-danger float-right"
          @click="closeGroupUsers">
          <span class="fas fa-times fa-2x "></span>
        </button>
      </h5>

      <form
        action="#"
        class="form-inline mb-3" >
        <div class="form-group mr-3 ">
          <input
            v-model="filter.term"
            style="width: 300px"
            type="text"
            class="form-control"
            placeholder="Search employee firstname."
          >
        </div>
        <div class="form-group">
          <select
            id=""
            v-model="filter.status"
            name=""
            class="custom-select mr-2"
          >
            <option
              value="Status"
              selected>Status</option>
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </select>
        </div>

        <button
          class="btn btn-primary"
          @click.prevent="get(filter.current_page, filter.per_page, filter.term)"
        >
          Search
        </button>

        <span class="text-muted ml-2">Selected employee: {{ countSelected }}
          <button
            v-if="countSelected"
            class="btn btn-outline-info btn-sm mx-2"
            @click="selectedUser=[]">Clear</button>
          <button
            v-if="countSelected"
            class="btn btn-primary btn-sm mx-2"
            @click.prevent="attachToGroup">Save</button>
        </span>
      </form>
      <template class="my-2">
        <progress-bar :status="progress"></progress-bar>
      </template>

      <div
        v-if="progress==false" 
        class="card-columns">
        <div
          v-for="(user, i) in filter.data"
          :key="i"
          class="card shadow-sm border-0">
          <div class="card-body">
            <div class="custom-control custom-checkbox">
              <input
                :id="'u'+i"
                v-model="selectedUser"
                :value="user.id"
                type="checkbox"
                class="custom-control-input">
              <label 
                :for="'u'+i" 
                class="custom-control-label" >{{ user.firstname }} {{ user.lastname }}</label>
            </div>
         
          </div>
        </div>
      </div>

      <paginate
        :pagination="filter"
        :offset="4"
        class="mt-2"
        @paginate="get(filter.current_page, filter.per_page, filter.term)">
      </paginate>
    </div>
  </div>
</template>
