<template>
  <ul class="nav justify-content-center">
    <li class="nav-item">
      <a 
        class="nav-link active" 
        href="/dashboard">
        <i class="material-icons md-24 mr-2">home</i>
        Dashboard
      </a>
    </li>
  
    <li class="nav-item">
      <a 
        class="nav-link" 
        href="/clients">
        <i class="material-icons md-24 mr-2 ">account_box</i>
        Clients
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "MobileNav",
  data() {
    return {};
  }
};
</script>

<style>
</style>
