<template lang="pug">
  .card.shadow-sm.rounded 
    .card-body
        h5.card-title Members
          m-icon(
              :options="'text-muted hover ml-2'"
              @click.native="add=!add"
              v-if="own"
          ) add
        .form-group( 
                v-click-outside="c"
            v-if="add"
        )
          client-search(
        
            v-on:get-data="q=$event"
            :type="false"
          )
        .card.border-0
            ul.list-group.list-group-flush
                li(
                    v-for="(e, i) in users"
                    :key="i"
                  ).list-group-item.p-1
                    img(
                        :src="'http://www.onevirtualsolutions.com/account/avatar/'+e.avatar"
                        style="width: 32px; height: 32px"
                    ).rounded-circle.img-fluid
                    strong.ml-2 {{ e.firstname }} {{ e.lastname }}
                    m-icon(
                      :options="'text-muted hover-red ml-2 float-right md-16'"
                      @click.native="removeE(e,i)"
                      v-if="own"
                    ) delete
</template>

<script>
export default {
  name: "ProjectMembers",
  props: {
    content: {
      required: true,
      type: [Object, Array],
      default: null
    }
  },
  data() {
    return {
      users: this.$store.state.project.users,
      add: false,
      q: [],
      own: this.$store.state.project.own
    };
  },
  watch: {
    q() {
      this.appendE();
    }
  },
  methods: {
    c() {
      this.add = false;
    },
    appendE() {
      __m
        ._http(
          "post",
          "/_p/" + this.$store.state.project.id + "/add/" + this.q.id + "/user"
        )
        .then(r => {
          if (r.data == "old") {
            alert("Please select new member!");
          } else {
            this.users.push(this.q);
          }
        });
    },
    removeE(e, i) {
      if (confirm("Remove this member?")) {
        __m
          ._http(
            "delete",
            "/_p/" + this.$store.state.project.id + "/remove/" + e.id + "/user"
          )
          .then(r => {
            this.$delete(this.users, i);
          });
      }
    }
  }
};
</script>
