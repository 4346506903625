<template>
  <div class="container-fluid">
    <template v-if="open">
      <work-create
        :content="edited"
        :work-type="create"
        :options="options"
        @cancel="open=$event"
        @update="update=$event"
      ></work-create>
    </template>
    <template v-else>
      <!-- <template v-if="load">
        <progress-screen></progress-screen>
      </template>-->
      <template>
        <div class="row">
          <div class="col-12 mt-2">
            <div class="form-row">
              <div class="input-group col-md-4 col-12">
                <input
                  v-model="filter"
                  type="text"
                  name
                  class="form-control"
                  placeholder="Filter by keyword"
                >
              </div>

              <div class="form-group col-md-2 col-sm-12">
                <select 
                  v-model="state" 
                  name 
                  class="custom-select state">
                  <option 
                    v-for="(e, i) in states" 
                    :key="i" 
                    :value="e.value" 
                    v-text="e.text"></option>
                </select>
              </div>

              <div class="form-group col">
                <button 
                  class="btn btn-primary" 
                  @click="createTask">
                  <m-icon class="p-0">add</m-icon>New Task
                </button>
              </div>

              <div class="form-group col">
                <button
                  :class="trash==true ? 'btn-success' : 'btn-danger'"
                  class="btn float-right"
                  @click="trash=!trash"
                >
                  <m-icon class="p-0">{{ icon }}</m-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-md-12">
            <table class="table table-hover table-borderless">
              <thead>
                <tr >
                  <th>
                    Title
                    <span @click="order={text: 'title', orders: 'desc'}">
                      <span class="material-icons">arrow_drop_down</span>
                    </span>
                    <span @click="order={text: 'title', orders: 'asc'}">
                      <span class="material-icons">arrow_drop_up</span>
                    </span>
                  </th>
                  <th></th>
                  <th class="text-center">Hours</th>
                  <th>
                    State
                    <span @click="order={text: 'state', orders: 'desc'}">
                      <span class="material-icons">arrow_drop_down</span>
                    </span>
                    <span @click="order={text: 'state', orders: 'asc'}">
                      <span class="material-icons">arrow_drop_up</span>
                    </span>
                  </th>
                  <th>Changed Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="progress">
                  <td 
                    class="align-middle" 
                    colspan="6">
                    <div style="width: 50%; margin: 0 auto; text-align:center">
                      <progress-bar :status="progress"></progress-bar>
                      <p class="p-1">loading..</p>
                    </div>
                  </td>
                </tr>
                <tr 
                  v-for="(e, i) in filterdWorks"
                  v-else 
                  :key="i" 
                  class="shadow-sm" 
                  @dblclick="openWork(e)">
                  <td>
                    <div class="row m-0">
                      <small
                        v-for="(label, i) in e.labels"
                        :key="i"
                        :title="label.title"
                        :style="'cursor: hand; text-transform: uppercase; color: #fff; background-color:'+ label.color"
                        class="p-1 px-3 d-block d-inline rounded mr-1"
                        @click="showlabel=!showlabel"
                      >
                        <template v-if="showlabel">{{ label.title }}</template>
                      </small>
                    </div>
                    <span 
                      class="black-text d-block my-1" 
                      @click.prevent="openWork(e)">{{ e.title }}</span>
                    <div class="align-items-center">
                      <template v-if="e.tasks.length">
                        <span v-if="e.tasks.length">
                          <m-icon :options="' md-22 orange-text darken-1'">assignment_turned_in</m-icon>
                          {{ countTask(e.tasks) }}
                        </span>
                      </template>
                      <template v-if="e.due_at">
                           
                        <m-icon 
                          :options="' md-22 gray-text darken-1'"
                          :class="due_at(e.due_at) ? 'red-text' : 'text-muted' ">timer</m-icon>
                      </template>
                    </div>
                  </td>
                  <td class="text-center align-middle">
                    <user-info
                      v-if="e.user_id"
                      :content="e.user_id"
                      :show-name="false"
                      :small="true"
                    ></user-info>
                  </td>
                  <td class="text-center">{{ e.hours }}</td>
                  <td>{{ e.state|workState(true) }}</td>
                  <td
                    :title="e.updated_at | dateFormat('dddd, MMM DD, YYYY hh:mm:ss A')"
                  >{{ e.updated_at | dateFormat('YYYY-MM-DD hh:mm A') }}</td>
                </tr>
                <tr v-if="filterdWorks.length == 0 && !progress">
                  <td colspan="10">
                    <div style="width: 50%; margin: 0 auto; text-align:center">
                      <p class="p-1">
                        We didn't find anything - please try again
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <paginate
              v-if="filterdWorks.length != 0 && progress==false"
              :pagination="works"
              :offset="4"
              @paginate="items(works.current_page, works.per_page)"
            ></paginate>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>
<script>
import hh from "./methods/WorkItemsMethods";
import ww from "./methods/WorkItemsWatch";
export default {
  name: "WorkItems",
  data() {
    return {
      newTaskTimeOut: null,
      newTaskEntries: 2500,
      showlabel: false,
      trash: false,
      load: false,
      project: this.$store.state.project.id,
      options: {
        type: false // true  -> old , false -> new
      },
      edited: [],
      order: {
        text: "text",
        orders: "asc"
      },
      update: false,
      create: null,
      type: "",
      state: "",
      source: null,
      progress: true,
      open: null,
      states: [
        { text: "All", value: "" },
        { text: "New", value: 3 },
        { text: "Active", value: 1 },
        { text: "Resolved", value: 4 }
      ],
      creates: [{ text: "Story", value: 1 }, { text: "Task", value: 2 }],
      types: [
        { text: "All", value: "" },
        { text: "Task", value: "2" },
        { text: "Story", value: "1" }
      ],
      works: {
        total: 0,
        per_page: 12,
        from: 1,
        to: 0,
        current_page: 1,
        data: []
      },
      filterItems: [],
      filter: ""
    };
  },
  computed: {
    icon() {
      return this.trash == true ? "delete" : "delete_outline";
    },
    filterdWorks() {
      let __t = this;

      let c = _.orderBy(__t.filterItems, __t.order.text, __t.order.orders)
        .filter(e => {
          return e.state.toString().includes(this.state);
        })
        .filter(e => {
          return e.type.toString().includes(this.type);
        });

      return c;
    }
  },
  watch: ww,
  created() {
    this.load = true;
    this.items(this.works.current_page, this.works.per_page);
  },
  beforeDestroy() {
    this.source();
  },
  methods: hh
};
</script>
