import { Line } from "vue-chartjs";
export default {
  name: "chartjs",
  extends: Line,
  props: {
    data: {
      required: true,
      data: [Object, Array]
    },
    options: {
      required: false,
      data: [Object, Array],
      default() {
        return { responsive: false, maintainAspectRatio: true };
      }
    }
  },

  mounted() {
    this.renderChart(this.data, this.options);
  }
};
