<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <div class="card border-light shadow rounded">
            
            
          <div class="card-body">
            <h3 class="card-title text-center py-4 ">Login</h3>
                            
            <form 
              method="POST" 
              @submit.prevent="login" >
                     
              <div class="form-group ">
                <label 
                  for="email" 
                  class=" text-md-left">Email</label>
                <input 
                  id="email" 
                  type="email" 
                  class="form-control" 
                  :class="(errors.get('company_email') ? 'is-invalid' : '')" 
                  name="company_email" 
                  v-model="data.company_email" 
                  required
                  autofocus="true">
                <span 
                  class="invalid-feedback" 
                  v-if="errors.get('company_email')">
                  <strong>{{ errors.get('company_email') }}</strong>
                </span>
                              
              </div>

              <div class="form-group ">
                <label 
                  for="password" 
                  class="">Password</label>

                <input 
                  id="password" 
                  type="password" 
                  :class="(errors.get('password') ? 'is-invalid' : '')" 
                  v-model="data.password" 
                  class="form-control " 
                  name="password" 
                  required>
                <span 
                  class="invalid-feedback" 
                  v-if="errors.get('password')">
                  <strong>{{ errors.get('password') }}</strong>
                </span>
                             
                                
              </div>
                        
              <div class="form-group row mb-0">
                <div class="col">
                  <button 
                    type="submit" 
                    class="btn btn-primary" 
                    :disabled="data.login">
                    <span 
                      class="fa fa-sync fa-spin mr-2" 
                      v-if="data.login"></span>
                    Login
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import error from "../../errors";
export default {
  namne: "login",
  data() {
    return {
      errors: new error(),
      data: {
        login: false,
        rcapchaid: 0,
        key: "6LejPXIUAAAAABO6hzhR6RmeXr4rL-gBN81U0Ybv"
      }
    };
  },
  methods: {
    login() {
      this.data.login = true;
      __m
        ._http("post", "/login", this.data)
        .then(e => {
          window.location.href = e.data;
          this.data.login = false;
        })
        .catch(e => {
          this.errors.record(e.response.data["errors"]);

          this.data.login = false;
        });
    },
    renderCapcha() {
      if (window.grecaptcha) {
        let cap = document.querySelector("#capcha");
        this.rcapchaid = grecaptcha.render(cap, {
          sitekey: this.data.key,
          callback: e => {
            this.data.capcha = e;
          }
        });
      }
    }
  },
  mounted() {
    if (window.grecaptcha) {
      this.rcapchaid = grecaptcha.render("capcha", {
        sitekey: "6LclJFoUAAAAAF0c2oYXQI7S3uBe5xMFGVR1Zum3",
        callback: e => {
          this.data.capcha = e;
        }
      });
    }
  }
};
</script>

<style>
</style>
