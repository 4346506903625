import Vue from "vue";
/**
 *  Global filter
 */
Vue.filter("setActivePage", (e, url) => {
  let page = url.split("/");
  return e == page[1] ? "active" : "";
});
Vue.filter("lcfirst", e => {
  let first = e.charAt(0).toUpperCase();
  return first + e.slice(1);
});
Vue.filter("statusFilter", e => {
  return e == "ACTIVE-CLIENT" ? "Active" : "Inactive";
});

Vue.filter("dateFormat", (e, formatString = null, human = false) => {
  if (e)
    return formatString != null
      ? moment(e).format(formatString)
      : moment(e).format("MMM, Do YYYY");
});

Vue.filter("countWork", (f, d = false, s = null) => {
  let c = 0;
  f.forEach(function(e) {
    if (d == true) {
      if (e.state == 2 || e.state == 4) c++;
    } else {
      if (s != null) {
        if (e.state == s) c++;
      } else {
        if (e.state == 3 || e.state == 1) c++;
      }
    }
  });
  return c;
});

// work state
Vue.filter("workState", (e, d = false) => {
  let state = [0, "active", "new", "closed", "resolved"];

  let state2 = [
    { text: "Active", value: 1 },
    { text: "Closed", value: 2 },
    { text: "New", value: 3 },
    { text: "Resolved", value: 4 }
  ];

  if ((e == "") | (e == null)) return e;
  let filterWorkState = _.find(state2, ["value", e]);
  return d == true ? filterWorkState.text : _.indexOf(state, e.toLowerCase());
});

Vue.filter("avatar", e => {
  return e != "" ? "/files/avatars/" + e : "/files/avatars/empty-avatar.png";
});

Vue.filter("workType", (e, d = false) => {
  let type = [0, "story", "task"];
  let type2 = [{ text: "Story", value: 1 }, { text: "Task", value: 2 }];
  let v = _.find(type2, ["value", e]);
  if (e == "" || e == null) return e;
  return "Task";
  //return d == true ? v.text : _.indexOf(type, e.toLowerCase());
});

Vue.filter("workColor", e => {
  if (e == "" || e == null) return e;
  return "orange-text darken-1";
  // return e == 1 ? "blue-text darken-1" : "orange-text darken-1";
});

Vue.filter("userInfo", e => {
  if (e == "" || e == null) return e;
  let c = __m._http("get", "/u/" + e).then(e => {
    return e;
  });
  return c;
});

Vue.filter("due_at", e => {
  if (e == null) {
    return null;
  } else if (e != "" || e != null) {
    let c = moment();
    let f = moment(e);

    return f.diff(c) > 0 ? false : true;
  }
});
