class Model {
  static _http(method, url, data, cancel = false) {
    const CancelToken = axios.CancelToken;
    let source;

    let p = axios({
      method: method,
      url: __base_uri + url,
      data: data,
      cancelToken: new CancelToken(function executor(c) {
        source = c;
      })
    });

    if (cancel) {
      return {
        p,
        source
      };
    } else {
      return p;
    }
  }
}
export default Model;
