<template lang="pug">
  span.text-underline <slot></slot>
</template>

<script>
export default {
  name: "TaskDone"
};
</script>
<style scoped>
.text-underline {
  text-decoration: line-through;
  color: rgb(148, 148, 148);
  font-style: italic;
}
</style>
