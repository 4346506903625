<template lang="pug">
.container-fluid
    .row.border-bottom.mb-2.pb-2
        .col-md-12
            work-header 
                template(v-if="tEdit==false") {{ project.title }}
                    template(
                        v-if="project.own"
                    )
                        m-icon(
                            :options="'text-muted hover'"
                            @click.native="tEdit=!tEdit"
                        ) edit
                .form-row(
                    v-if="tEdit"
                )
                    .form-group(
                        v-click-outside="et"
                    ).col-9.col-md-10
                        input(
                            type="text"
                            v-on-focus
                            v-model="newtitle"
                        ).form-control.form-control-sm
                    .form-group.col-3.col-md-2
                        
                        button(
                            @click="storeUpdate"
                            :disabled="progress"
                        ).btn.btn-primary.btn-sm 
                            template(
                                v-if="progress==false"
                            ) Save
                            template(
                                v-else
                            ) 
                                i.fas.fa-spinner.fa-pulse.white-text
                        
                        m-icon(
                            :options="'text-muted hover'"
                            @click.native="tEdit=false"
                        ).ml-1 close

    .row
        .col-md-7.col.mb-2
            .card.shadow-sm.rounded
                .card-body(
                      v-click-outside="c"
                )
                    h5.card-title About Project 
                        m-icon(
                            v-if="project.own"
                            :options="'text-muted hover'"
                            @click.native="desEdit=!desEdit"
                        ) edit
                    p.card-text(
                        v-if="desEdit==false"
                    ) {{ project.description }}  
                    .form-row(
                      
                        v-else
                    )
                        .form-group.col
                            textarea(
                                v-model="newDes"
                                row="3"
                                v-on-focus
                            ).form-control.mb-2
                          
                            button(
                                @click="storeDes"
                                :disabled="progress"
                            ).btn.btn-primary.btn-sm 
                                template(
                                    v-if="progress==false"
                                    ) Save
                                template(
                                    v-else
                                ) 
                                    i.fas.fa-spinner.fa-pulse.white-text
                            m-icon(
                                :options="'text-muted hover'"
                                @click.native="desEdit=false"
                            ).ml-1 close              

        .col-md-5
            .row
                .col-md-12.mb-2
                    .card.shadow-sm.rounded.mb-1
                        .card-body  
                            h5.card-title Project stats
                            p.text-muted  Created {{ project.created_at | dateFormat("MMM DD, YYYY h:mm:ss A", true) }}
                            .row
                                .col-md-12
                                    strong.text-muted Boards
                                .col 
                                    m-icon(
                                        :options="'text-muted'"
                                    ).mr-1 work_outline 
                                    strong {{ boards.length }}
                                    br/
                                    small.text-muted Task created
                                .col 
                                    m-icon(
                                        :options="'blue-text darken-1'"
                                    ).mr-1 work_outline 
                                    strong {{ boards | countWork(false, 1) }}
                                    br/
                                    small.text-muted Task Active
                                .col 
                                    m-icon(
                                            :options="'text-muted'"
                                    ).mr-1 work_off 
                                    strong {{ boards | countWork(true) }}
                                    br/
                                    small.text-muted Task completed
    
                .col-md-12.mb-2
                    project-client(
                        :content="clients"
                    )
                    
                
                .col-md-12.mb-2
                    project-members(
                        :content="users"
                    )
</template>
<script>
import pp from "../methods/ProjectInfoMethods";
export default {
  name: "ProjectInfo",
  data() {
    return {
      project: this.$store.state.project,
      users: this.$store.state.project.users,
      clients: this.$store.state.project.clients,
      boards: this.$store.state.project.stories,
      newDes: this.$store.state.project.description,
      labels: this.$store.state.project.labels,
      tEdit: false,
      newtitle: this.$store.state.project.title,
      u: this.$store.state.user,
      desEdit: false,
      progress: false,
      u: this.$store.state.user
    };
  },
  computed: {
    d() {
      let a = [1, 10];
      let c = 0;
      for (let index = 0; index < a.length; index++) {
        if (a[index] == this.u.group_id) {
          c++;
        }
      }
      return c > 0 ? true : false;
    }
  },
  watch: {
    // tEdit() {
    //   this.tEdit == true
    //     ? (this.project.own = false)
    //     : (this.project.own = true);
    // }
    // desEdit() {
    //   this.desEdit == true
    //     ? (this.project.own = false)
    //     : (this.project.own = true);
    // }
  },
  beforeMount() {
    document.title = title + " " + this.$store.state.project.title;
  },
  methods: pp
};
</script>
