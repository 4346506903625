<template lang="pug">
.row
    //- .col-md-12.mb-2
    //-     .card.border-0.shadow-sm
    //-         .card-body
    //-             b.card-title Details
    //-             p.text-muted.py-0.m-0 Created {{workItem.created_at | dateFormat("MMM DD, YYYY h:mm:ss A", true)}}
    //-             p.text-muted.py-0.mas-0 Updated  {{workItem.updated_at | dateFormat("MMM DD, YYYY h:mm:ss A")}} 
    .col-md-12.mb-2
      .card.border-0.shadow-sm
        .card-body
          template(
            v-if="!duedate"
          )
            strong.card-title(
              @click="duedate=dateNow"
            ) Due Date
          template(
            v-else
          )
            small Due Date
            DatePicker(
              v-model="duedate"
              clearable
            )
      //-   .card.border-none.shadow-sm
      //-       .card-body
      //-           b.card-title Details
      //-           p.text-muted.py-0.m-0 Created {{workItem.created_at | dateFormat("MMM DD, YYYY h:mm:ss A", true)}}
      //-           p.text-muted.py-0.mas-0 Updated {{workItem.updated_at | dateFormat("MMM DD, YYYY h:mm:ss A")}} 
    
    .col-md-12.mb-2
        .card.border-0.shadow-sm
            .card-body 
                small(
                    v-if="workItem.user_id"
                ) Assigned To
                work-user(
                    :assigned="workItem"
                )
    .col-md-12
        .card.border-0.shadow-sm
            .card-body
                b.card-title Labels
                    m-icon(
                        :options="'ml-1 hover'"
                        @click.native="newlabel=true"
                        v-if="newlabel==false"
                    ) add

                template(
                    v-if="newlabel"
                    )
                    form(
                        @submit.prevent="storeLabel"
                        v-click-outside="cl"
                        method="post"
                        enctype="multipart/form-data"
                        @keydown="errors.clear($event.target.name)" 
                    ).mt-2    
                        .form-row
                            .form-group.col-8.m-0
                              input(
                                  required
                                  type="text"
                                  v-model="labeldata.title"
                                  placeholder="Add new label"
                              ).form-control
                              template(
                                v-if="errors.get('title')" 
                              )
                                small.small.text-danger.left {{ errors.get('title') }}
                            .form-group.col
                              button(
                                  @click="create=true"
                                  :disabled="addlbl"
                              ).btn.btn-primary 
                                template(
                                  v-if="addlbl"
                                ) 
                                  i.fas.fa-spinner.fa-spin.fa-lg
                                template(
                                  v-else
                                ) Add
                        .container
                            .row
                                .col-12.p-0
                                    small(
                                        v-if="!errors.get('color')" 
                                    ) Choose color
                                    template(
                                      v-if="errors.get('color')" 
                                    )
                                      small.small.text-danger.left {{ errors.get('color') }}
                                .col-2(
                                    v-for="(label, i) in labels"
                                    :key="i"
                                    :style="'background:' + label.color"
                                    @click="setColor(label)"
                                )
                                    m-icon(
                                        :options="label.color == selected ? 'white-text' : 'opacity-blur'"
                                        style="margin-left: -10px;"
                                    ) check
                        .container.mt-2
                            .row
                                .col-12.p-0.pb-2.mb-1.border-bottom
                                    small Click to remove
                                span(
                                    v-for="(label, i) in projectLabels"
                                    :key="i"
                                    @click="r(label,i)"
                                    :style=" 'text-transform:capitalize; color: #555; border: solid 1px ' + label.color  "
                                ).text-capitalize.text-center.m-1.p-1.rounded {{ label.title }}     
                template(
                        v-else
                    )            
                    .container.mt-2
                            .row
                                span(
                                    v-for="(label, i) in projectLabels"
                                    :key="i"
                                    :style="a(label.color) == true ? 'color: #fff; background:' + label.color : 'border: solid 1px ' + label.color  + '; cursor: pointer' "
                                    @click="s(label,i)"
                                ).text-capitalize.text-center.m-1.rounded.py-1.px-2 {{ label.title }}
</template>
<script>
import e from "../../errors";
export default {
  name: "WorkDetails",
  props: {
    content: {
      required: false,
      type: Object,
      default: null
    }
  },
  data() {
    return {
      duedate: this.content.due_at,
      errors: new e(),
      newlabel: false,
      labeldata: [],
      workItem: this.content,
      addlbl: false,
      text: null,
      selected: "",
      labels: [
        {
          color: "#007bff"
        },
        {
          color: "#28a745"
        },
        {
          color: "#dc3545"
        },
        {
          color: "#ffc107"
        },

        {
          color: "#17a2b8"
        },
        {
          color: "#343a40"
        }
      ],
      projectLabels: this.$store.state.project.labels,
      workLabel: this.content.labels,
      p: this.$store.state.project.id
    };
  },
  computed: {
    dateNow() {
      return moment().format("MM/DD/YYYY");
    }
  },
  watch: {
    content() {
      this.workItem = this.content;
    },
    duedate: {
      handler: "due",
      deep: true
    }
  },
  methods: {
    due() {
      let date =
        this.duedate != ""
          ? moment(this.duedate, "MM/DD/YYYY").format("MM/DD/YYYY")
          : null;

      __m
        ._http(
          "post",
          "/_w/" + this.p + "/work/" + this.workItem.id + "/duedate",
          {
            due_at: date
          }
        )
        .then(r => {
          this.workItem.due_at = this.duedate;
        })
        .catch(() => {});
    },
    cl() {
      this.newlabel = false;
    },
    r(e, i) {
      if (confirm("Delete " + e.title + "? This cannot be undone")) {
        __m
          ._http(
            "delete",
            "/_p/" + this.$store.state.project.id + "/label/delete"
          )
          .then(() => {
            this.$delete(this.projectLabels, i);
          });
      }
    },
    a(e) {
      let b = this.workLabel;

      let c = _.find(b, ["color", e]);
      return c ? true : false;
    },
    g(e) {
      this.selected = e;
    },
    s(e, i) {
      console.log(e);
      // store the work label
      let data = {
        project_id: this.p,
        work_id: this.workItem.id,
        label_id: e.id
      };
      __m
        ._http("patch", "/_w/" + data.project_id + "/label/store", data)
        .then(() => {
          _.find(this.workLabel, ["color", e.color])
            ? this.$delete(this.workLabel, i)
            : this.workLabel.push(e);
        });
    },
    storeLabel() {
      // store project label
      this.addlbl = true;
      let data = {
        title: this.labeldata.title,
        color: this.labeldata.color
      };

      let x = _.findIndex(this.projectLabels, ["color", this.labeldata.color]);
      this.$delete(this.projectLabels, x);
      __m
        ._http("post", "/_p/" + this.$store.state.project.id + "/label", data)
        .then(r => {
          this.labeldata = [];
          this.selected = "";
          this.projectLabels.push(r.data);
          this.addlbl = false;
        })
        .catch(r => {
          this.addlbl = false;
          this.errors.record(r.response.data["errors"]);
        });
    },
    setColor(e) {
      this.errors.clear("color");
      this.labeldata.color = e.color;
      this.selected = e.color;
    }
  }
};
</script>
<style lang="css" scoped>
.opacity-blur {
  opacity: 0;
}
</style>
