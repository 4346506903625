<template>
  
  <div>
    <div class="row">
      <div class="col">
        <h3>
          {{ filterLabel }}
          <datePicker 
            v-model="dateFilter.dateRange"
            @input="get" 
            format="YYYY-MM-DD" ></datePicker>
        </h3>
        <p><b class="text-danger">Total Lost Hours: {{ totalHours }} </b>  <b class="text-warning">Attrition Count: {{ attritionCount }}</b></p>
      </div>
    </div>
    <div class="row">
      <div
        class="col-md-12 my-3 shadow-sm  rounded" 
        v-for="(t,d) in teams" 
        :key="d" 
      >
        <h4
          class="my-2" 
          v-text="t.name">
        </h4>
        <div class="table-responsive">
          <table class="table ">
            <thead class=" thead-ligth ">
              <tr>
                <th 
                  v-for="(i,l) in table.head" 
                  :key="l" 
                  v-text="i"></th>
              </tr>
            </thead>
            <tbody >
              <template v-for="u in t.members">
                <template v-for="c in u.user.clients">
                  <template >
                    <tr 
                      v-for="(a, l) in c.info.attritions" 
                      :key="l">
                      
                      <td>{{ a.created_at | dateFormat("MMM. DD, YYYY") }}</td>
                      <td>{{ u.user.firstname }} {{ u.user.lastname }}</td>
                      <td>{{ c.info.firstname }} {{ c.info.lastname }} </td> 
                      <td>{{ c.info.hours }} </td> 
                      <td>{{ a.att_category.content }}</td>
                      <td>{{ a.comment }}</td>
                    </tr>
                  </template>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: "AttritionDashboard",
  data() {
    return {
      teams: [],
      clients: [],
      members: {},
      dateFilter: {
        dateRange: [moment().startOf("month"), moment().endOf("month")]
      },
      totalHours: 0,
      attritionCount: 0,
      table: {
        head: [
          "#",
          "Effective Date",
          "Virtual Assistant",
          "Client Name",
          "Work Hours",
          "Category",
          "Reason for Cancellation"
        ]
      }
    };
  },

  methods: {
    get() {
      let __this = this;
      const from = moment(__this.dateFilter.dateRange[0]).format("YYYY-MM-DD");
      const to = moment(__this.dateFilter.dateRange[1]).format("YYYY-MM-DD");
      __m
        ._http("get", "att/all?from=" + from + "&to=" + to)
        //  ._http("get", "att/filter?from="+from+"&to="+to)
        .then(e => {
          __this.teams = e.data;
        })
        .catch(e => {});
    }
  },
  computed: {
    filterLabel() {
      let from = moment(this.dateFilter.dateRange[0]).format("MMM. DD");
      let to = moment(this.dateFilter.dateRange[1]).format("DD, YYYY");

      return from + "-" + to;
    }
  },
  beforeMount() {
    this.get();
  }
};
</script>

<style>
</style>
