<template lang="pug">
div.md-0.p-0
  nav.col-md-2.d-none.d-sm-none.d-md-block.sidebar
    .sidebar-sticky.pt-0
      ul.nav.flex-column.nav-item-list
        //- h6.sidebar-heading.px-3.mt-2.mb-1.blue-text
        //-   i.material-icons.mr-2 work
        //-   strong Works
        li(
          class="nav-item"
          v-for="(e,i) in content"
          :class=" (active) ? active==e ? 'active' : '' : current==i ? 'active' : ''"
          :key="i"
        )
          a(
            class="nav-link"
            @click="getNav(e)"
          ) 
            i.material-icons.mr-2 {{ e.icon }}  
            span(
              v-if="type==false"
            ) {{ e.text }}

  .col-md-12.p-0
   ul.nav.nav-inline.border-bottom
      li(
        class="nav-item"
        v-for="(e,i) in content"
        :class=" (active) ? active==e ? 'active' : '' : current==i ? 'active' : ''"
        :key="i"
      )
        a(
          class="nav-link"
          @click="getNav(e)"
        ) 
          i.material-icons.mr-2 {{ e.icon }}  
          span(
            v-if="type==false"
          ) {{ e.text }}
           
</template>

<script>
export default {
  name: "SideNav",
  props: {
    small: {
      type: Boolean,
      default: false,
      required: false
    },
    current: {
      type: null,
      default: null,
      required: false
    },
    content: {
      required: false,
      default: null,
      type: null
    }
  },
  data() {
    return {
      active: null,
      addClass: null,
      type: this.small
    };
  },
  mounted() {},

  methods: {
    getNav(e) {
      document.title = title + " " + e.text;
      if (e.href) {
        window.location.href = e.href;
      }
      this.active = e;
      this.$emit("activenav", e);
    }
  }
};
</script>
<style >
li.nav-item {
  color: #6d6d6d;
}
li.nav-item.active {
  background: #e2e2e2;
  color: #161616;
}
</style>
