<template lang="pug">
.container-fluid
  
  .row.p-0.m-0
    .col-md-6.p-1
        .card.border-0.shadow-sm
          .card-body
            h5.card-title {{ project.title }}
            p.text-muted  {{ project.description }}
            .row
             
              .col 
                  m-icon(
                      :options="'text-muted'"
                  ).mr-1 group 
                  strong {{ project.users.length }}
                  br/
                  small.text-muted Members
              
    .col-md-6.p-1
      .card.border-0.shadow-sm
        .card-body
          h5.card-title Project stats
          p.text-muted  Created {{ project.created_at | dateFormat("MMM DD, YYYY h:mm:ss A", true) }}
          .row
            .col-md-12
                strong.text-muted Boards
            .col 
                m-icon(
                    :options="'text-muted'"
                ).mr-1 work_outline 
                strong {{ project.stories.length }}
                br/
                small.text-muted Task items created
            .col 
                m-icon(
                    :options="'blue-text darken-1'"
                ).mr-1 work_outline 
                strong {{ project.stories | countWork(false, 1) }}
                br/
                small.text-muted Task items Active
            .col 
                m-icon(
                        :options="'text-muted'"
                ).mr-1 work_off 
                strong {{ project.stories | countWork(true) }}
                br/
                small.text-muted Task items completed
          .row
            .col
              button(
                @click="c"
                :disabled="progress"
              ).btn.btn-danger 
                template(
                  v-if="progress"
                ) 
                  i.fas.fa-spinner.fa-spin.fa-lg
                template(
                  v-else
                ) Archive
          
    
    
</template>
<script>
export default {
  name: "WorkSetting",
  data() {
    return {
      project: this.$store.state.project,
      progress: false
    };
  },
  methods: {
    c() {
      this.progress = true;
      __m._http("post", "/_p/" + this.project.id + "/archive").then(r => {
        window.location.href = "/_projects";
      });
    }
  }
};
</script>
