<template>
  <span 
    v-if="countNotifications"
    class="badge badge-danger">{{ countNotifications }}</span>                      
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "NotificationCount",
  computed: {
    countNotifications() {
      return this.$store.state.notifications.length;
    }
  }
};
</script>

<style>
</style>
