<template lang="pug">
 main.container-fluid
    .row
        .col-10
            h5 Projects 
        .col-2(
               v-if="create==false"      
        )
            button(
                @click.stop="create=!create"
            ).btn.btn-primary.float-right New Project
        div(
            v-if="create"
        ).col-12.p-3.shadow.mb-3.rounded
            form(
                v-click-outside="c"
                @keydown="errors.clear($event.target.name)" 
                @submit.prevent="addproject"
               
            )
                .form-row
                    .col-md-8
                        input(
                            type="text"
                            required
                            name="title"
                            v-on-focus
                            v-model="created.title"
                            placeholder="Enter Project name"
                            :class="errors.get('title') ? ' is-invalid' : '' "
                        ).form-control
                        small(
                            v-if="errors.get('title')" 
                        ).small.text-danger.left {{ errors.get('title') }}
                    .col-md-2
                        .custom-control.custom-checkbox.mt-2
                            input(
                                type="checkbox"
                                id="open"
                                v-model="open"
                                true-value="true"
                                false-value="false"
                            ).custom-control-input
                            label(for="open").custom-control-label Open on create
                    .col
                        button.btn.btn-primary
                            i(
                                v-if="progress"
                            ).fas.fa-spinner.fa-spin
                            span(
                                v-else
                            ) Add
                        button(
                            @click.prevent="create=false"
                            ).btn.btn-light.ml-2 Cancel
            
        div(
            v-for="(e, i) in projects"
            :key="i"
        ).col-sm-12.col-md-3.m-0.p-2
            project-item(
                :content="e"
            )
</template>
<script>
import e from "../../../errors";
export default {
  name: "ProjectList",
  data() {
    return {
      errors: new e(),
      projects: this.$store.state.projects,
      created: {},
      create: false,
      open: "false",
      progress: false
    };
  },
  beforeMount() {},

  methods: {
    c() {
      this.create = false;
    },
    clearErrors(e) {
      console.log(e);
      this.errors.clear(e.target.name);
    },
    addproject(e) {
      this.progress = true;
      __m
        ._http("post", "/_p/create", this.created)
        .then(r => {
          this.progress = false;
          this.created = {};
          this.$store.dispatch("addProjects", r.data);
          if (this.open == "true") {
            window.location.href = "/_p/" + r.data.id;
          }
        })
        .catch(r => {
          this.progress = false;
          this.created = {};
          this.errors.record(r.response.data["errors"]);
        });
    }
  },
};
</script>
