<template lang="pug">
  .card.shadow.border-0.project-item(
      @click="open"
  ) 
    progress-bar(
      :status="progress"
    )
    .card-body
        
        h5.card-title.text-truncate {{ data.title }}
       
        .row
          .col
            m-icon.mr-1.blue-text.darken-1 work_outline 
            strong {{ data.stories | countWork(false, 1) }}
            br/
            small.text-muted Task Items
              
          .col
            m-icon(
              :options="'mr-1 text-muted'"
            )  group 
            strong {{ data.users.length }}
            br/
            small.text-muted Members
</template>

<script>
export default {
  name: "ProjectItem",
  props: {
    content: {
      type: [Array, Object],
      required: true,
      default: null
    }
  },
  data() {
    return {
      data: this.content,
      progress: false
    };
  },
  methods: {
    open() {
      let __t = this;
      if (this.data.hasOwnProperty("pivot") == false) {
        window.location.href = "/_p/" + this.data.id;
      } else {
        if (this.data.pivot.deleted_at == null) {
          window.location.href = "/_p/" + this.data.id;
        } else {
          if (confirm("Do want to restore this project?")) {
            __t.progress = true;
            __m._http("patch", "/_p/" + this.data.id + "/restore").then(r => {
              __t.progress = false;
              __t.$store.dispatch("addProjects", r.data);
              __t.$store.dispatch("restoreArchive", r.data);
            });
          }
        }
      }
    }
  }
};
</script>
