<template lang="pug">
  main.container-fluid
    .row
        .col-12
            h5 Archives 

        div(
            v-for="(e, i) in archives"
            :key="i"
        ).col-sm-12.col-md-3.m-0.p-2
            project-item(
                :content="e"
            )
   
</template>
<script>
export default {
  name: "ProjectArchiveList",
  data() {
    return {
      archives: this.$store.state.archives
    };
  }
};
</script>
