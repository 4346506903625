export default {
  setProject({ commit }, project) {
    commit("set_project", project);
  },

  clearProject({ commit }) {
    commit("clear_project");
  },
  addProjects({ commit }, projects) {
    commit("add_projects", projects);
  },
  setProjects({ commit }, projects) {
    commit("set_projects", projects);
  },
  clearProjects({ commit }) {
    commit("clear_projects");
  },
  removeProjects({ commit }, projects) {
    commit("remove_projects", projects);
  },
  restoreArchive({ commit }, projects) {
    commit("restore_archive", projects);
  },
  setUser({ commit }, user) {
    commit("set_user", user);
  },
  // notifications
  setNotifications({ commit }, notifications) {
    commit("set_notification", notifications);
  },
  removeNotification({ commit }, notification) {
    commit("remove_notification", notification);
  },
  removeNotifications({ commit }) {
    commit("remove_notifications");
  }
};
