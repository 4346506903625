<template>
  <div>
    <h5>Category</h5>
    <div
      class="mb-2" 
      @keydown="errors.clear($event.target.name)" >
      <div class="form-row">
        <div class=" col-md-10">
          <input 
            type="text" 
            name="content" 
            class="form-control form-control-sm"
            id="newStatus"
            placeholder="Enter New Category"
            :class="( errors.get('content') ) ? 'is-invalid' : '' "
            v-model="category.content">
          <span
            class="text-danger left small"
            v-if="errors.get('content')" 
            v-text="errors.get('content')">
          </span>
        </div>
        
        <div class=" col-md-2" >
          <button
            type="button" 
            class="btn btn-primary btn-sm"
            :disabled="category.progress" 
            @click.prevent="storeStatus">
            <i 
              class="fas fa-sync fa-spin" 
              v-if="category.progress">
            </i>
            Save
          </button>
        </div>
      </div>
    </div>
    <table class="table table-sm table-hover">
      <thead>
        <tr>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        <tr 
          v-for="(i,p) in category.data" 
          :key="p">
          <td><span v-text="i.content"></span></td>                   
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
import validateResponse from "../../errors"

export default {
  name: "AttritionCategory",
  data () {
    return {
      errors: new validateResponse(), 
   
      category: {
        progress: false,
        name: "Status",
        data: {},
        content: null
      },
    }
  },
  methods: {
    getCategory () {
      __m._http("get", "att/category")
      .then(e => {
        this.status.data = e.data
      })
      .catch(e => {

      })
    },
    storeCategory () {
      let __this  = this;
      __this.status.progress = true
      __m._http('post', "att/category/store", __this.status)
      .then(e => {
        __this.status.progress = false
        __this.getStatus()
        __this.status.content = ""
      })
      .catch(e => {
       __this.errors.record(e.response.data["errors"])
        __this.status.progress = false
        
      })
    }
  },
  beforeMount () {
    this.getCategory()
  }
}
</script>

<style>

</style>
