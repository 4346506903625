let data = {
  countCreated() {
    return this.created.length;
  },
  countActive() {
    return this.active.length;
  },
  countResolved() {
    return this.resolved.length;
  },
  countClosed() {
    return this.closed.length;
  }
};
export default data;
