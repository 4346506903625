<template lang="pug">
  .row(
      ref="test"
  )
    .col-12(
         @click="assign=!assign"
    )
        span(
            v-if="user"
            ) 
                img(
                    :src="'http://www.onevirtualsolutions.com/account/avatar/'+u.avatar"
                    style="width: 32px; height: 32px"
                ).img-thumbnail.rounded-circle.img-fluid.border-0
        
        strong.ml-2 {{ u.firstname+' '+u.lastname }}
        m-icon(
            v-if="u.id!=null"
            :options="'text-muted hover md-16'"
            @click.native="a({ firstname: 'Unsigned',id: null})"
        ).ml-2 close
    .col-12(
            v-click-outside="c"
            v-if="assign"
        ).mt-2
            small Select Member
            progress-bar(
                :status="progress"
            )
            ul(
                v-on-scroll
                style="max-height: 50vh"
            ).list-group.list-group-flush
                a(
                    v-for="(e, i) in m"
                    :key="i"
                    @click="a(e)"
                    ).list-group-item.p-1.list-group-item-action
                    img(
                        :src="'http://www.onevirtualsolutions.com/account/avatar/'+e.avatar"
                        style="width: 32px; height: 32px"
                    ).img-thumbnail.rounded-circle.img-fluid.border-0
                    span.ml-2 {{ e.firstname }} {{ e.lastname }}
    
</template>
<script>
export default {
  name: "WorkUser",
  props: {
    assigned: {
      default: null,
      type: [Object],
      required: true
    }
  },
  data() {
    return {
      user: this.assigned.user_id,
      members: this.$store.state.project.users,
      assign: false,
      progress: false
    };
  },

  computed: {
    u() {
      let c = _.find(this.members, ["id", this.assigned.user_id]);
      return this.user != null
        ? c
        : {
            firstname: "Unsigned",
            lastname: "",
            id: null,
            avatar: null
          };
    },
    m() {
      return _.filter(this.members, e => {
        return e.id != this.user;
      });
    }
  },
  methods: {
    c() {
      this.assign = false;
    },

    a(e) {
      this.progress = true;
      __m
        ._http(
          "patch",
          "/_p/" +
            this.$store.state.project.id +
            "/" +
            this.assigned.id +
            "/u/assign",
          {
            id: e.id
          }
        )
        .then(r => {
          this.$parent.workItem.user_id = e.id;
          this.user = e.id;
          this.progress = false;
        })
        .catch(r => {
          this.progress = false;
        });
    }
  }
};
</script>
