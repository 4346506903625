export default {
  filter() {
    this.items(this.works.current_page, this.works.per_page)
  },
  trash() {
    this.items(this.works.current_page, this.works.per_page);
  },
  create() {
    this.open = true;
    this.edited = {
      title: null,
      description: null,
      state: 3,
      labels: [],
      user_id: null,
      type: this.create
    };
    this.options = {
      type: false
    };
  },
  open() {

    // this.items(this.works.current_page, this.works.per_page);
  },
  update() {
    if (this.update == true) {
      this.items(this.works.current_page, this.works.per_page);
     // this.open = false;
    }
  }
};
