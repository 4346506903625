<template lang="pug">
    .row.align-items-center(
        style="height: 80vh"
    )
        .col-md-12(
            style="text-align:center"
        )
            div
                i.fas.fa-spinner.fa-spin.fa-lg
            b.text-muted Loading
</template>

<script>
export default {
  name: "ProgressScreen"
};
</script>
<style>
</style>
