<template lang="pug">
.container-fluid
  template(
     v-if="open"
  )
    work-create(
      :content="edited"
      :work-type="create"
      :options="options"
      @update="update=$event"
      @data="c=$event"
      @cancel="open=$event"
      :itemIndex="workIndex"
    )
  template(
    v-else
  )
    
        .row.pt-2.no-gutters
            //- .col-md-12.mb-2
            //-     button(
            //-         @click="createTask(3)"
            //-     ).btn.btn-primary.btn-sm
            //-         span New Task
            //-     template(
            //-         v-if="load"
            //-     )
            //-         //- progress-bar(
            //-         //-     :status="load"
            //-         //- )

            //-         span.fas.fa-circle-notch.fa-spin.text-primary.fa-lg.ml-2
            //-         span.ml-1.align-middle Loading please wait.
            .col-md-3
                .board-container.board-item 
                    h6 New 
                        
                        template(
                            v-if="load"
                        )
                            //- progress-bar(
                            //-     :status="load"
                            //- )
                        
                            
                            span.fas.fa-circle-notch.fa-spin.text-primary.fa-lg.ml-2
                            //span.ml-1.align-middle Loading please wait.

                        span(
                            @click="createTask(3)"
                        ).fas.fa-plus.float-right.task-add

                        //span.float-right.text-muted.mr-2 {{ countCreated }}
                    draggable-container(
                        v-model="created"
                        :options="op"
                        :move="onMove"
                        @change="setStateNew($event, 3)"
                        v-on-scroll
                        v-auto-height
                    ).task-container
                        div(
                            v-for="(e,i) in created"
                            :key="i"
                            @dblclick="openWork(e, i)"
                        ).rounded.p-2.mb-2.shadow-sm.task-card
                            progress-bar(
                                :status="progress == e.id"
                            )
                
                            .row.m-0.mt-1.rounded
                                small(
                                    class="p-1 px-3 rounded mr-2 mb-1"
                                    v-for="(label, i) in e.labels"
                                    :key="i"
                                    :title="label.title"
                                    :style="'text-transform: uppercase; color: #fff; background-color:'+ label.color"
                                    @click="showlabel =! showlabel"
                                )
                                    template(
                                        v-if="showlabel"
                                    ) {{ label.title }}

                                .col-md-12.m-0.p-0.py-1.text-truncate
                                    span(
                                        @click="openWork(e)"
                                    ) {{ e.title }}
                                
                                .col-md-12.p-0
                                    .col-md-12.p-0
                                        .row.m-0

                                            template(
                                                v-if="countTask(e.tasks)"
                                            )
                                                .col.p-0
                                                    span.fas.fa-clipboard.orange-text.darken-1.mr-2
                                            
                                                    span(
                                                        @click="showTask(e)"
                                                    ).mr-2.text-muted {{ countTask(e.tasks) }}

                                            template(
                                                v-if="e.due_at"
                                            )
                                                .col.p-0
                                                    span.far.fa-clock(
                                                        :class=" due_at(e.due_at) ?  'red-text' : 'text-muted' "
                                                    )
                                    
                                            .col.p-0
                                                user-info(
                                                    v-if="e.user_id"
                                                    :content="e.user_id"
                                                    :show-name="false"
                                                    :small="true"
                                                ).float-right

                                .col-md-12.p-0
                                    ul( 
                                        v-if="countTask(e.tasks)"
                                        :id="'story-'+e.id"
                                        v-on-scroll
                                        style="max-height: 260px;"
                                    ).list-group.list-group-flush.hidden.rounded.mt-2
                                        li(
                                            v-for="(t, i) in e.tasks"
                                        ).list-group-item.p-1.item-action
                                            .custom-control.custom-checkbox
                                                input(
                                                    :id="'t'+t.info.id"
                                                    type="checkbox"
                                                    v-bind:checked="t.info.state==2"
                                                    @change="toogleDone(t.info, 2, 1)"
                                                ).custom-control-input
                                                label(
                                                    :for="'t'+t.info.id"
                                                ).custom-control-label
                                                    task-done(
                                                    v-if="t.info.state==2"
                                                    ).ml-1 {{ t.info.title }}
                                                    span(
                                                    v-else
                                                    ).ml-1 {{ t.info.title }}
                                             
                                                span(
                                                    @click="removeTask(e, i)"
                                                ).far.fa-trash-alt.float-right.hover.mt-1.text-muted

                                        .form-group.p-2.m-0
                                            input(
                                                placeholder="Enter new Task"
                                                v-model="t"
                                                type="text"
                                                @keyup.enter="addTask(e)"
                                            ).form-control.form-control-sm.readonly
      
      
            .col-md-3
                .board-container.board-item 
                    h6 Active 
                        //- .float-right.mr-3(
                        //-     style="cursor: pointer;"
                        //-     @click="createTask(1)"
                        //- )
                        //-     span.fa.fa-plus
                        // span.float-right.mr-2.text-muted {{ countActive }}
                    draggable-container(
                        v-model="active"
                        :options="op"
                        :move="onMove"
                         @change="setStateNew($event, 1)"
                        v-on-scroll
                        v-auto-height
                    ).task-container
                        div(
                            v-for="(e,i) in active"
                            :key="i"
                            @dblclick="openWork(e)"
                        ).rounded.p-2.mb-2.shadow-sm.task-card
                            progress-bar(
                                :status="progress == e.id"
                            )
                
                            .row.m-0.mt-1.rounded
                                small(
                                    class="p-1 px-3 rounded mr-2 mb-1"
                                    v-for="(label, i) in e.labels"
                                    :key="i"
                                    :title="label.title"
                                    :style="'text-transform: uppercase; color: #fff; background-color:'+ label.color"
                                    @click="showlabel =! showlabel"
                                )
                                    template(
                                        v-if="showlabel"
                                    ) {{ label.title }}
                                .col-md-12.m-0.p-0.py-1.text-truncate(
                                    :class="due_at(e.due_at)"
                                )
                                    span(
                                    @click="openWork(e)"
                                    ) {{ e.title }}
                                .col-md-12.p-0
                                    div.col-md-12.p-0
                                        .row.m-0
                                            template(
                                                v-if="countTask(e.tasks)"
                                            )
                                           
                                                .col.p-0
                                                    span.fas.fa-clipboard.orange-text.darken-1.mr-2
                                            
                                                    span(
                                                        @click="showTask(e)"
                                                    ).mr-2.text-muted {{ countTask(e.tasks) }}

                                            template(
                                                v-if="e.due_at"
                                            )
                                                .col.p-0
                                                    span.far.fa-clock(
                                                        :class=" due_at(e.due_at) ?  'red-text' : 'text-muted' "
                                                    )
                                            .col.p-0
                                                user-info(
                                                    v-if="e.user_id"
                                                    :content="e.user_id"
                                                    :show-name="false"
                                                    :small="true"
                                                ).float-right
                                .col-md-12.p-0
                                    ul( 
                                        v-if="countTask(e.tasks)"
                                        :id="'story-'+e.id"
                                        v-on-scroll
                                        style="max-height: 260px;"
                                    ).list-group.list-group-flush.hidden.rounded.mt-2
                                        li(
                                            v-for="(t, i) in e.tasks"
                                        ).list-group-item.p-1.item-action
                                            .custom-control.custom-checkbox
                                                input(
                                                    :id="'t'+t.info.id"
                                                    type="checkbox"
                                                    v-bind:checked="t.info.state==2"
                                                    @change="toogleDone(t.info, 2, 1)"
                                                ).custom-control-input
                                                label(
                                                    :for="'t'+t.info.id"
                                                ).custom-control-label
                                                    task-done(
                                                    v-if="t.info.state==2"
                                                    ).ml-1 {{ t.info.title }}
                                                    span(
                                                    v-else
                                                    ).ml-1 {{ t.info.title }}

                                                span(
                                                        @click="removeTask(e, i)"
                                                    ).far.fa-trash-alt.float-right.hover.mt-1.text-muted


                                        .form-group.p-2.m-0
                                            input(
                                                placeholder="Enter new Task"
                                                v-model="t"
                                                type="text"
                                                @keyup.enter="addTask(e)"
                                            ).form-control.form-control-sm.readonly
                        

      


            .col-md-3
                .board-container.board-item
                    h6 Resolved 
                        //- .float-right.mr-3(
                        //-     style="cursor: pointer"
                        //-     @click="createTask(4)"
                        //- )
                        //-     span.fa.fa-plus
                        //span.float-right.mr-2.text-muted {{ countResolved }}
                    draggable-container(
                        v-model="resolved"
                        :options="op"
                        :move="onMove"
                        @change="setStateNew($event, 4)"
                        v-on-scroll
                        v-auto-height
                    ).task-container
                        div(
                            v-for="(e,i) in resolved"
                            :key="i"
                            @dblclick="openWork(e)"
                        ).rounded.p-2.mb-2.shadow-sm.task-card
                            progress-bar(
                                :status="progress == e.id"
                            )
                
                            .row.m-0.mt-1.rounded
                                small(
                                    class="p-1 px-3 rounded mr-2 mb-1"
                                    v-for="(label, i) in e.labels"
                                    :key="i"
                                    :title="label.title"
                                    :style="'text-transform: uppercase; color: #fff; background-color:'+ label.color"
                                    @click="showlabel =! showlabel"
                                )
                                    template(
                                        v-if="showlabel"
                                    ) {{ label.title }}
                                .col-md-12.m-0.p-0.py-1.text-truncate
                                    span(
                                        @click="openWork(e)"
                                    ) {{ e.title }}
                                .col-md-12.p-0
                                    div.col-md-12.p-0
                                        .row.m-0
                                            template(
                                                v-if="countTask(e.tasks)"
                                            )
                                            
                                                .col.p-0
                                                    span.fas.fa-clipboard.orange-text.darken-1.mr-2
                                            
                                                    span(
                                                        @click="showTask(e)"
                                                    ).mr-2.text-muted {{ countTask(e.tasks) }}

                                            template(
                                                v-if="e.due_at"
                                            )
                                                .col.p-0
                                                    span.far.fa-clock(
                                                        :class="'text-muted'"
                                                    )
                                    
                                            .col.p-0
                                                user-info(
                                                    v-if="e.user_id"
                                                    :content="e.user_id"
                                                    :show-name="false"
                                                    :small="true"
                                                ).float-right
                                .col-md-12.p-0
                                    ul( 
                                    v-if="countTask(e.tasks)"
                                    :id="'story-'+e.id"
                                    v-on-scroll
                                    style="max-height: 260px;"
                                    ).list-group.list-group-flush.hidden.rounded.mt-2
                                        li(
                                            v-for="(t, i) in e.tasks"
                                        ).list-group-item.p-1.item-action
                                            .custom-control.custom-checkbox
                                                input(
                                                    :id="'t'+t.info.id"
                                                    type="checkbox"
                                                    v-bind:checked="t.info.state==2"
                                                    @change="toogleDone(t.info, 2, 1)"
                                                ).custom-control-input
                                                label(
                                                    :for="'t'+t.info.id"
                                                ).custom-control-label
                                                    task-done(
                                                    v-if="t.info.state==2"
                                                    ).ml-1 {{ t.info.title }}
                                                    span(
                                                    v-else
                                                    ).ml-1 {{ t.info.title }}
                                                span(
                                                    @click="removeTask(e, i)"
                                                ).far.fa-trash-alt.float-right.hover.mt-1.text-muted


                                        .form-group.p-2.m-0
                                            input(
                                                placeholder="Enter new Task"
                                                v-model="t"
                                                type="text"
                                                @keyup.enter="addTask(e)"
                                            ).form-control.form-control-sm.readonly
                
                
            .col-md-3
                   
                .board-container.board-item
                    h6 Closed 
                        //-     .float-right.mr-3(
                        //-         style="cursor: pointer"
                        //-     )
                        //-         span.fa.fa-plus
                        //span.float-right.mr-2.text-muted {{ countClosed }}
                    draggable-container(
                        v-model="closed"
                        :options="op"
                        :move="onMove"
                        @change="setStateNew($event, 2)"
                        v-on-scroll
                        v-auto-height
                    ).task-container
                        div( 
                            v-for="(e,i) in closed"
                            :key="i"
                            @dblclick="openWork(e)"
                        ).rounded.p-2.mb-2.shadow-sm.task-card
                            progress-bar(
                                :status="progress == e.id"
                            )
                
                            .row.m-0.mt-1.rounded
                                small(
                                    class="p-1 px-3 rounded mr-2 mb-1"
                                    v-for="(label, i) in e.labels"
                                    :key="i"
                                    :title="label.title"
                                    :style="'text-transform: uppercase; color: #fff; background-color:'+ label.color"
                                    @click="showlabel =! showlabel"
                                )
                                    template(
                                        v-if="showlabel"
                                    ) {{ label.title }}
                                .col-md-12.m-0.p-0.py-1.text-truncate
                                    span(
                                    @click="openWork(e)"
                                    ) {{ e.title }}
                                .col-md-12.p-0
                                    div.col-md-12.p-0
                                        template(
                                            v-if="countTask(e.tasks)"
                                        )
                                            .row.m-0
                                                .col.p-0
                                                    span.fas.fa-clipboard.orange-text.darken-1.mr-2
                                            
                                                    span(
                                                        @click="showTask(e)"
                                                    ).mr-2.text-muted {{ countTask(e.tasks) }}

                                                template(
                                                    v-if="e.due_at"
                                                )
                                                    .col.p-0
                                                        span.far.fa-clock(
                                                            :class="'text-muted'"
                                                        )
                                        
                                                .col.p-0
                                                    user-info(
                                                        v-if="e.user_id"
                                                        :content="e.user_id"
                                                        :show-name="false"
                                                        :small="true"
                                                    ).float-right

                                .col-md-12.p-0
                                    ul( 
                                        v-if="countTask(e.tasks)"
                                        :id="'story-'+e.id"
                                        v-on-scroll
                                        style="max-height: 260px;"
                                    ).list-group.list-group-flush.hidden.rounded.mt-2
                                        li(
                                            v-for="(t, i) in e.tasks"
                                        ).list-group-item.p-1.item-action
                                            .custom-control.custom-checkbox
                                                input(
                                                    :id="'t'+t.info.id"
                                                    type="checkbox"
                                                    v-bind:checked="t.info.state==2"
                                                    @change="toogleDone(t.info, 2, 1)"
                                                ).custom-control-input
                                                label(
                                                    :for="'t'+t.info.id"
                                                ).custom-control-label
                                                    task-done(
                                                    v-if="t.info.state==2"
                                                    ).ml-1 {{ t.info.title }}
                                                    span(
                                                    v-else
                                                    ).ml-1 {{ t.info.title }}
                                                    
                                                span(
                                                    @click="removeTask(e, i)"
                                                ).far.fa-trash-alt.float-right.hover.mt-1.text-muted


                                        .form-group.p-2.m-0
                                            input(
                                                placeholder="Enter new Task"
                                                v-model="t"
                                                type="text"
                                                @keyup.enter="addTask(e)"
                                            ).form-control.form-control-sm.readonly
</template>
<script>
import boardsComputed from "./methods/computed";

export default {
  name: "BoardList",
  data() {
    return {
      workIndex: null,
      list: [],
      t: null,
      s: null,
      tr: [],
      total: [],
      interval: 2500,
      count: [],
      load: false,
      showlabel: false,
      options: {
        group: "works",
        type: false
      },
      op: {
        group: "works",
        type: false
      },
      deleted: [],
      c: null,
      source: null,
      project: this.$store.state.project.id,
      update: false,
      updatedState: null,
      items: [],
      create: 1,
      active: [],
      created: [],
      resolved: [],
      closed: [],
      edited: null,
      open: false,
      progress: null,
      states: [
        { text: "New", type: 3, icon: "work_outline" },
        { text: "Active", type: 1, icon: "bookmark_border" },
        { text: "Resolved", type: 4, icon: "done" },
        { text: "Closed", type: 2, icon: "close" }
      ],
      btn: "btn-outline-secondary btn-sm"
    };
  },
  computed: boardsComputed,
  watch: {
    update() {
      if (this.update == true) {
        // this.open = false;
        this.getItems();
      }
    },
    c() {
      this.created.push(this.c);
    }
    //total: "getItems"
  },
  mounted() {
    this.getItems(entries => {
      this.total = entries;
      this.checkNewTask();
    });
    //this.checkNewTask();
  },
  beforeDestroy() {
    window.clearTimeout(this.interval);
    window.clearTimeout(this.s);
    this.source();
  },
  methods: {
    setStateNew(e, i) {
      let __t = this;
      if (e.added) {
        let data = { state: i };
        __t.progress = e.added.element.id;
        __m
          ._http("post", "/_w/" + e.added.element.id + "/state", data)
          .then(r => {
            this.progress = null;
            e.added.element.state = 3;
          });
      } else if (e.moved) {
        let data = {
          idSort: e.moved.newIndex,
          state: i,
          oldIndex: e.moved.oldIndex
        };
        console.log(data);
        __m._http(
          "put",
          "/_w/" + this.project + "/" + e.moved.element.id,
          data
        );
      }
    },

    createTask(s) {
      this.open = true;
      this.options.type = false;
      this.edited = {
        title: null,
        description: null,
        state: s,
        labels: [],
        user_id: null,
        type: this.create
      };
      this.options = {
        type: false
      };
    },
    due_at(e) {
      if (e == null) {
        return null;
      } else if (e != "" || e != null) {
        let c = moment();
        let f = moment(e);

        return f.diff(c) > 0 ? false : true;
      }
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    setStatus(e, i, type, container) {
      let __t = this;
      __t.$set(e, "fixed", true);
      function remove(con, i) {
        if (con == 1) {
          __t.$delete(__t.active, i);
        } else if (con == 2) {
          __t.$delete(__t.closed, i);
        } else if (con == 3) {
          __t.$delete(__t.created, i);
        } else if (con == 4) {
          __t.$delete(__t.resolved, i);
        }
      }
      function pushData(con, data) {
        if (con == 1) {
          __t.active.push(data);
        } else if (con == 2) {
          __t.closed.push(data);
        } else if (con == 3) {
          __t.created.push(data);
        } else if (con == 4) {
          __t.resolved.push(data);
        }
      }
      __t.progress = e.id;
      __t.$set(e, "state", type);

      __m
        ._http("post", "/_w/" + e.id + "/update", e)
        .then(r => {
          pushData(type, e);
          remove(container, i);
          __t.progress = null;
          this.$set(e, "fixed", false);
        })
        .catch(e => {});
    },
    // edited
    checkNewTask() {
      this.s = window.setTimeout(() => {
        __m._http("post", "/_p/task/count/" + this.project).then(r => {
          if (r.data["data"].length != this.total.data.length) {
            this.getNewItems();
          } else {
            this.checkNewTask();
          }
        });
      }, this.interval);
    },
    getNewItems() {
      clearTimeout(this.s);
      this.getItems(entries => {
        this.total = entries;
        this.checkNewTask();
      });
    },
    getItems(after) {
      this.load = true;
      let c = __m._http("get", "/_b/" + this.project + "/items", {}, true);
      this.source = c.source;
      c.p.then(e => {
        this.active = e.data.active;
        this.created = e.data.new;
        this.closed = e.data.closed;
        this.resolved = e.data.resolved;
        this.update = false;
        this.load = false;
        this.total = e.data;
        this.tr = e.data;

        if (_.isFunction(after)) {
          after((this.total = e.data));
        }
        //this.checkNewTask();
      });
    },
    // done edited
    openWork(e, index) {
      this.workIndex = index;
      this.open = true;
      this.edited = e;
      this.options.type = true;
    },
    showTask(e) {
      let c = this.$el.querySelector("#story-" + e.id);
      c.classList.toggle("hidden");
    },
    countTask(e) {
      let d = 0;
      e.forEach(c => {
        if (c.info["state"] === 2) d++;
      });
      let c = d && e.length ? d + "/" + e.length : 0 + "/" + e.length;
      return e.length != 0 ? c : "";
    },
    toogleDone(e, closeState, activeState) {
      if (e.state != closeState) {
        e.state = closeState;
        __m._http("patch", "/_w/state/" + e.id, { state: closeState });
      } else {
        e.state = activeState;
        __m._http("patch", "/_w/state/" + e.id, { state: activeState });
      }
    },
    addTask(e) {
      if (this.t != "") {
        __m
          ._http("post", "/_w/store/" + e.id + "/task", { title: this.t })
          .then(r => {
            this.t = "";
            e.tasks.push(r.data);
          });
      } else {
        alert("Task is required.");
      }
    },
    removeTask(e, i) {
      this.$set(e, "deleted", true);
      if (confirm("Remove Task?")) {
        __m._http("delete", "/_w/remove/" + e.tasks[i].id + "/task").then(r => {
          this.$delete(e.tasks, i);
        });
      }
    },
    checkDeleted(e) {
      // let c = _.findIndex(this.deleted, e);
    }
  }
};
</script>
<style scoped>
.item-action:hover {
  background: rgb(236, 236, 236);
  cursor: pointer;
}
.hover:hover {
  cursor: pointer;
  color: rgb(220, 0, 1) !important;
}
</style>
