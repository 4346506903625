<template>
  <transition name="fade">
    <div 
      :class="'alert-'+options.type+' '+options.hidden" 
      class="alert" 
      role="alert">
      {{ options.content }}
      <button 
       
        type="button" 
        class="close" 
       
       
        data-dismiss="alert" 
        aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </transition>
</template>
<script>
export default {
  name: "BAlert",
  props: {
    type: {
      type: String,
      default: () => {
        return "primary";
      }
    },
    content: {
      type: null,
      default: () => {
        return null;
      }
    },
    auto: {
      type: String,
      default: () => {
        return false;
      }
    },
    options: {
      type: Object,
      default() {
        return {
          hidden: "hidden",
          type: "success",
          content: null,
          duration: 3000
        };
      }
    }
  },
  created() {
    setTimeout(() => {
      this.$parent.alert.content = "";
    }, 3000);
  },

  methods: {}
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
