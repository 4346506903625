let c = {
  countTask(e) {
    let d = 0;

    e.forEach(c => {
      if (c.info["state"] === 2) d++;
    });
    let c = d && e.length ? d + "/" + e.length : 0 + "/" + e.length;
    return e.length != 0 ? c : "";
  },
  storeTask() {
    if (this.t.length != 0) {
      this.s = true;
      __m
        ._http("post", "/_w/store/" + this.content.id + "/task", {
          title: this.t
        })
        .then(r => {
          this.t = "";
          this.s = false;
          this.tasks.push(r.data);
        });
    }
  },
  toogleDone(e, closeState, activeState) {
    if (e.state != closeState) {
      e.state = closeState;
      __m._http("patch", "/_w/state/" + e.id, { state: closeState });
    } else {
      e.state = activeState;
      __m._http("patch", "/_w/state/" + e.id, { state: activeState });
    }
  },
  removeTask(e, i) {
    if (confirm("Remove Task?")) {
      __m._http("delete", "/_w/remove/" + e.id + "/task").then(r => {
        this.$delete(this.tasks, i);
      });
    }
  }
};
export default c;
