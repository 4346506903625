<template lang="pug">
   div
    div
      h5.text-muted.pt-1 Checklist
      span.text-muted.my-2
        m-icon(
        :options="'md-16 mr-1 '"
        ) assignment_turned_in 
        span {{ countTask(tasks) }}
    div(
      v-on-scroll
      v-auto-height="380"
    )
      ul.list-group.list-group-flush
        li(
          v-for="(e, i) in tasks"
          @change="toogleDone(e.info, 2, 1)"
        ).list-group-item.list-group-item-action.py-1
          .form-check-input
            input(
              :id="'c'+e.info.id"
              type="checkbox"
              v-bind:checked="e.info.state==2"
            )
          label(
            :for="'c'+e.info.id"
          ).form-check-label
              task-done(
                v-if="e.info.state==2"
              ).ml-1 {{ e.info.title }}
              span(
                v-else
              ).ml-1 {{ e.info.title }}
          

          span(
            @click="removeTask(e, i)"
          ).far.fa-trash-alt.float-right.hover.mt-1.text-muted

    div.my-2
      div.form-row
        div.col-md-11
          input(
            type="text"
            v-model="t"
            placeholder="Add Item"
            :class=" added == false ? 'readonly' : '' "
            @keyup.enter="storeTask"
        ).form-control.form-control-sm
        div.col-md-1
         
          button(
            @click="storeTask"
            ).btn.btn-sm.btn-success 
                m-icon(
                  v-if="s"
                  :type="'fa'" 
                  :options="'fas fa-spinner fa-spin fa-sm '"
                ) 
                span(
                  v-else
                )  Save
    
       
       
</template>
<script>
import TasksMethods from "./TasksMethods";
export default {
  name: "TaskList",
  props: {
    content: {
      type: Object,
      required: true,
      default: null
    }
  },
  data() {
    return {
      tasks: this.content.tasks,
      t: null,
      s: false,
      edited: null,
      update: null,
      open: false,
      added: false,
      e: []
    };
  },
  computed: {
    addedTask() {
      return "read-only";
    }
  },
  watch: {
    added() {
      if (this.added == true) {
        this.addedTask();
      }
    },
    content() {
      this.tasks = this.content.tasks;
    }
  },
  methods: TasksMethods
};
</script>
