<template>
  <div :class="pre">

    <a
      :class="`${pre}-week`" 
      v-for="w in weeks" 
      :key="w">{{ w }}</a>
    
    <div 
      v-for="(j,i) in days"
      :class="
        [(j.p||j.n)?`${pre}-date-out`:'',
         status(j.y,j.m,j.i,hour,minute,second,'YYYYMMDD')]
      " 
      :key="i">
      <div class="center">{{ j.i }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Calendar",
  props: {
    value: {
          type: [String],
        default: () => {
            return null;
      }
    }
  },
  data() {
    const time = this.get(this.value);
    return {
      pre: "cal",
      date: time,
      year: time.year,
      month: time.month,
      day: time.day,
      hour: time.hour,
      minute: time.minute,
      second: time.second,
      weeks: "Sun_Mon_Tue_Wen_Thu_Fri_Sat".split("_") // weeks,
    };
  },
  watch: {
    value(e) {
      const _this = this;
      const time = _this.get(e);
      _this.year = time.year;
      _this.month = time.month;
      _this.day = time.day;
    }
  },
  computed: {
    format() {
      return this.$parent.format;
    },
    days() {
      const days = [];
      const _this = this;
      const year = _this.year;
      const month = _this.month;
      const time = new Date(year, month, 1);
      const dow = 0 || 7;
      time.setDate(0);
      let lastDay = time.getDate();
      const week = time.getDay() || 7;
      let count = dow <= week ? week - dow + 1 : week + (7 - dow + 1);

      while (count > 0) {
        days.push({
          i: lastDay - count + 1,
          y: month > 0 ? year : year - 1,
          m: month > 0 ? month - 1 : 11,
          p: true
        });
        count--;
      }
      time.setMonth(time.getMonth() + 2, 0); // switch to the last day of the current month
      lastDay = time.getDate();
      let i = 1;
      for (i = 1; i <= lastDay; i++) {
        days.push({
          i: i,
          y: year,
          m: month
        });
      }
      for (i = 1; days.length < 42; i++) {
        days.push({
          i: i,
          y: month < 11 ? year : year + 1,
          m: month < 11 ? month + 1 : 0,
          n: true
        });
      }
      return days;
    }
  },
  methods: {
    get(time) {
      return {
        year: time.getFullYear(),
        month: time.getMonth(),
        day: time.getDate(),
        hour: time.getHours(),
        minute: time.getMinutes(),
        second: time.getSeconds()
      };
    },
    parse(num) {
      return parseInt(num / 1000);
    },
    status(year, month, day, hour, minute, second, format) {
      const _this = this;
      const maxDay = new Date(year, month + 1, 0).getDate();
      const time = new Date(
        year,
        month,
        day > maxDay ? maxDay : day,
        hour,
        minute,
        second
      );
      const t = _this.parse(time);
      const f = _this.$parent.tf;
      const classObj = {};
      let flag = false;
      if (format === "YYYY") {
        flag = year === _this.year;
      } else if (format === "YYYYMM") {
        flag = month === _this.month;
      } else {
        flag = f(_this.value, format) === f(time, format);
      }
      classObj[`${_this.pre}-date`] = true;
      classObj[`${_this.pre}-date-disabled`] =
        (_this.right && t < _this.start) ||
        (_this.left && t > _this.end) ||
        _this.$parent.disabledDate(time, format);
      classObj[`${_this.pre}-date-on`] =
        (_this.left && t > _this.start) || (_this.right && t < _this.end);
      classObj[`${_this.pre}-date-selected`] = flag;
      return classObj;
    },
    getData() {
      const _this = this;
    }
  },

  mounted() {
    this.getData();
  }
};
</script>

<style scoped>
div {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;

  box-sizing: border-box;
}
.cal {
  width: 100%;
  height: 100%;
  margin-top: 1%;
  background-color: #fff;
  border: solid 1px #ddd;

  border-left: none;
}
.cal-date .center {
  text-align: center;
}
.cal-date-out {
  color: #ccc;
  opacity: 0.8;
}
.cal-date-selected .center {
  color: red;
}
.cal-date {
  font-weight: 400;
  width: 14.28%;
  height: 90px;
  border: solid 1px #ddd;
  border-bottom: none;
  border-right: none;
  box-sizing: border-box;
  display: inline-block;
}
.cal-week {
  font-weight: 500;
  width: 14.28%;
  height: 14.28%;
  padding: 6px 0 6px 0;
  text-align: center;
  box-sizing: border-box;
  display: inline-block;
  text-transform: uppercase;
  border-left: solid 1px #ddd;
}
</style>

