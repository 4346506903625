<template>
  <div class="row">
    <div class="container">
      <div class="col p-3">
        <h5>Add New Employee</h5>
        <form 
        
          action="#" 
          enctype="multipart/form-data" 
          id="form" 
          @submit.prevent="store" 
          novalidate>
                   
          <div class="form-row" >
            <div class="col border-bottom mb-2">
              <strong class="">Personal Info.</strong>
            </div>
            <div class="form-group col-md-12  mb-2">
              <div class="form-row">
                <div class="form-group  col-md-2">
                  <img 
                    class="roun" 
                    height="60px" 
                    width="60px"
                    v-if="avatar==null" 
                    :src="empty" 
                  >
                  <img 
                    class="roun" 
                    height="60px" 
                    width="60px"
                    v-else 
                    :src="avatar"
                  >
                  <input 
                    type="file" 
                    name="avatar" 
                    id="avatar" 
                    @change="onFileChange">
                </div>
                <!-- <div class="form-group col-md-4 align-self-center">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" >Profile</span>
                    </div>
                    <div class="custom-file">
                      <input 
                        type="file" 
                        class="custom-file-input" 
                        id="avatar" 
                        @change="onFileChange"
                      >
                      <label 
                        class="custom-file-label" 
                        for="avatar">Choose Image</label>
                    </div>
                  </div>
                </div> -->
              </div>
                             
                                

              <div v-if="avatar" ></div>
            </div>
                        
            <div class="form-group col-md-4">
              <label for="fname">First name:</label>
              <input 
                type="text" 
                name="firstname" 
                id="firstname" 
                placeholder="Enter lastname" 
                required 
                class="form-control">
            </div>
            <div class="form-group col-md-4">
              <label for="fname">Middle name:</label>
              <input 
                type="text" 
                name="middlename" 
                id="fname" 
                placeholder="Enter Middlename" 
                required 
                class="form-control">
            </div>
            <div class="form-group col-md-4">
              <label for="fname">Last name:</label>
              <input 
                type="text" 
                name="lastname" 
                id="fname" 
                placeholder="Enter lastname" 
                required 
                class="form-control">
            </div>
            <div class="form-group col-md-4">
              <label for="email">Email:</label>
              <input 
                type="text" 
                name="personal_email" 
                id="" 
                class="form-control" > 
            </div>
            <div class="form-group col-md-4">
              <label for="contact">Contact #:</label>
              <input 
                type="text" 
                name="home_number" 
                id="contact" 
                class="form-control" > 
            </div>
            <div class="form-group col-md-4">
              <label for="address">Address :</label>
              <input 
                type="text" 
                name="address" 
                id="address" 
                class="form-control"> 
            </div>
          </div>
         
          <div class="form-row mb-3">
            <div class="col-12 border-bottom mb-2">
              <strong class="">Company.</strong>
            </div>
            <div class="form-group col-md-3">
              <label for="em">ID.</label>
              <input 
                type="text" 
                name="employee_id" 
                id="em" 
                class="form-control" >
              <small 
                id="em" 
                class="form-text text-muted">
                Biometric Employee ID.
              </small>
            </div>
            <div class="form-group col-md-3 ">
              <label for="cemail">Email: </label>
              <input 
                type="email" 
                name="company_email" 
                id="cemail" 
                class="form-control" >
            </div>
          </div>

          <button class="btn btn-primary" >Save</button>
         
          
          <button 
            class="btn btn-danger" 
            @click.prevent="$emit('close', false)">Cancel</button>
          
              
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserCreate",
  data() {
    return {
      avatar: null,
      en: {},
      empty: "/files/avatars/empty-user.png"
    };
  },
  methods: {
    onFileChange(e) {
      this.en.avatar = e.target.files[0];
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return (this.avatar = "");

      this.createImage(files[0]);
    },

    createImage(file) {
      let reader = new FileReader();
      let __this = this;
      reader.onload = e => {
        __this.avatar = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    store() {
      let __t = this;
      let form = document.getElementById("form");
      let data = new FormData(form);
      data.append("avatar", document.getElementById("avatar").files[0]);
      const con = { headers: { "content-type": "multipart/form-data" } };
      __m._http("post", "employee/store", data, con);
      axios
        .post("employee/store", data, con)
        .then(e => {})
        .catch(e => {});
    }
  },
  beforeMount() {}
};
</script>

<style>
</style>
