<template>
  <div>
    <progress-bar :status="status"></progress-bar>
    <ul 
      class="nav nav-item-list" 
      :class="(mobile==true) ? 'mobile ' : 'flex-column' ">
      <template v-if="list">
        <li 
          class="nav-item" 
          v-for="(e,i) in clients" 
          :key="i" >
          <a 
            :href="'/team/'+e.id" 
            class="nav-link text-truncate" 
            :class="e.id | setActivePage(activeurl)" 
            :title="e.name">
            <i class="material-icons md-24 mr-2 ">group</i>
            {{ e.name }}
          </a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ClientList",
  props: {
    activeurl: {
      type: null,
      default: () => {
        return null;
      }
    },
    list: {
      type: Boolean,
      default: true
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      clients: [],
      status: false,
      activePage: null
    };
  },

  mounted() {
    this.status = true;
    __m._http("get", "/team/all").then(e => {
      this.status = false;
      this.clients = e.data;
    });
  }
};
</script>

<style scroped>
@media (max-width: 767.98px) {
  .mobile {
    overflow: scroll;
    flex-wrap: nowrap;
  }
}
</style>
