<template>
  <div>
    <h5>Account</h5>
  </div>
</template>

<script>
export default {
  name: "UserProfileDetails"
};
</script>

<style>
</style>
