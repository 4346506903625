<template>
  <div  
    :id="'modal-'+options.id"
    class="modal" 
    data-backdrop="static" 
    tabindex="-1" 
    role="dialog" >
    <div 
      role="document"
      class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ options.title }}</h5>
          <button 
            class="close" 
            @click.prevent="closeModal(options.data)"
            data-dismiss="modal" 
            aria-label="Close"
            type="button" >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form 
            @keydown="errors.clear($event.target.name)"
            @change="errors.clear($event.target.name)" 
            @blur="errors.clear($event.target.name)"
            class="form" >
            <div class="form-row">

              <div class="form-group col">
                <label for="fname">First name</label>
                <input 
                  type="text" 
                  v-model="options.data.firstname" 
                  :class="( errors.get('firstname') ) ? 'is-invalid' : '' " 
                  name="firstname" 
                  id="fname" 
                  class="form-control">
                <span 
                  v-if="errors.get('firstname')" 
                  v-text="errors.get('firstname')" 
                  class="text-danger left small"></span>
              </div>

              <div class="form-group col">
                <label for="lname">Last name</label>
                <input 
                  type="text" 
                  v-model="options.data.lastname" 
                  :class="( errors.get('lastname') ) ? 'is-invalid' : '' "  
                  name="lastname" 
                  id="lname" 
                  class="form-control">
                <span 
                  v-if="errors.get('lastname')" 
                  v-text="errors.get('lastname')" 
                  class="text-danger left small"></span>
              </div>

            </div>
                
            <div class="form-row">

              <div class="form-group col">
                <label for="email">Email</label>
                <input 
                  type="email" 
                  v-model="options.data.client_email" 
                  :class="( errors.get('client_email') ) ? 'is-invalid' : '' " 
                  name="client_email" 
                  id="email" 
                  class="form-control">
                <span 
                  v-if="errors.get('client_email')"
                  v-text="errors.get('client_email')" 
                  class="text-danger left small"></span>
              </div>

              <div class="form-group col-md-4 col-lg-3">
                <label for="status-edit">Status</label>
                
                <select 
                  class="form-control custom-select" 
                  :class="( errors.get('status') ) ? 'is-invalid' : ''" 
                  name="status" 
                  id="status-edit" 
                  v-model="options.data.status">
                  <option 
                    :value="options.data.status"  
                    selected >{{ options.data.status | statusFilter }}</option>
                  <option value="ACTIVE-CLIENT">Active</option>
                  <option value="INACTIVE-CLIENT">Inactive</option>
                </select>
                <span 
                  v-if="errors.get('status')" 
                  v-text="errors.get('status')"
                  class="text-danger left small"></span>
              </div>
            
            </div>
              
            <template v-if="options.data.status != 'ACTIVE-CLIENT' ">
              <div class="form-row">
                <div class="form-group col">
                  <label for="reasons">Reasons</label>
                  <input 
                    type="text" 
                    class="form-control" 
                    id="reasons" 
                    placeholder="Enter client reason" 
                    :class="( errors.get('reasons') ) ? 'is-invalid' : ''" >
                  <span 
                    v-if="errors.get('reasons')" 
                    v-text="errors.get('reasons')" 
                    class="text-danger left small"></span>
                </div>
                
                <div class="form-group col">
                  <label for="category">Category</label>
                  <select 
                    id="category" 
                    class="custom-select" 
                    :class="( errors.get('type') ) ? 'is-invalid' : ''" >
                    <option selected>Choose one</option>
                    <option value=""></option>
                  </select>
                  <span 
                    v-if="errors.get('type')"
                    v-text="errors.get('type')" 
                    class="text-danger left small"></span>
                </div>
              </div>
            </template>
              
            <div class="form-row mt-2">
              <div class="form-group col">
                <label for="rate">Work Hours</label>
                <input 
                  type="text" 
                  v-model="options.data.hours" 
                  :class="( errors.get('hours') ) ? 'is-invalid' : '' "  
                  name="hours" 
                  id="rate" 
                  class="form-control">
                <span 
                  v-if="errors.get('hours')"
                  v-text="errors.get('hours')" 
                  class="text-danger left small"></span>
              </div>
              <div class="form-group col">
                <label for="rate">Hourly Rate</label>
                <input 
                  type="text" 
                  v-model="options.data.rate" 
                  :class="( errors.get('rate') ) ? 'is-invalid' : '' "  
                  name="rate"
                  id="rate" 
                  class="form-control">
                <span 
                  v-if="errors.get('rate')" 
                  v-text="errors.get('rate')" 
                  class="text-danger left small"></span>
              </div>
            </div>
                
          </form>
        
        </div>
        <div class="modal-footer">
          <button 
            type="button" 
            @click="closeModal(options.data)" 
            class="btn btn-secondary" 
            data-dismiss="modal">Close</button>
          <button 
            type="button" 
            class="btn btn-primary">Save changes</button>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import error from "../../errors";
export default {
  props: {
    options: {
      required: true,
      type: [Number, String, Array, Object]
    }
  },
  data() {
    return {
      errors: new error()
    };
  },
  methods: {
    closeModal(e) {
      this.options.id = null;
      let __this = this;
      for (const key in e) {
        if (e.hasOwnProperty(key)) {
          const element = e[key];
          __this.$parent.beforeEdit[key] = element;
          console.log(e);
        }
      }
    }
  },

  mounted() {
    $("#modal-" + this.options.id).modal("show");
  }
};
</script>
