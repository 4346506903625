<template lang="pug">
  div
    input(
      type="text"
      :placeholder="placeholder"
      v-model="q"
    ).form-control
    progress-bar(
      :status="sp"
    )
    .form-group(
      v-on-scroll
      style="height: 30vh; z-index: 999; width: 90%"
      v-if="show.length"
    ).position-absolute
      .row
        .col-md-12
          ul.list-group
            li(
              v-for="(e, i) in r"
              @click="selected(e)"
            ).list-group-item.list-group-item-action.p-2 {{ e.firstname }} {{e.lastname}}
</template>

<script>
export default {
  name: "ClientSearch",
  props: {
    type: {
      default: true,
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      clients: this.content,
      add: false,
      placeholder:
        this.type == true ? "Enter Client lastname" : "Enter Employee lastname",
      q: "",
      r: [],
      sp: false,
      show: []
    };
  },
  watch: {
    q() {
      this.getClient();
    }
  },
  methods: {
    getClient: _.debounce(function() {
      let _t = this;
      let url = this.type == true ? "/client/q?q=" : "/em/q?q=";
      _t.sp = true;

      __m._http("get", url + _t.q).then(r => {
        _t.r = r.data;
        _t.show = r.data;
        _t.sp = false;
      });
    }, 200),

    selected(e) {
      this.$emit("get-data", e);
      this.show = [];
      this.q = e.firstname + " " + e.lastname;
    }
  }
};
</script>
</script>

<style>
</style>
