class Errors {
  constructor() {
    this.invalid = {};
  }
  has(field) {
    return this.invalid.hasOwnProperty(field);
  }

  get(field) {
    if (this.invalid[field]) {
      return this.invalid[field][0];
    }
  }
  record(invalid) {
    this.invalid = invalid;
  }
  clear(field) {
    if (field) {
      delete this.invalid[field];
      return;
    }
  }

  any() {
    return Object.keys(this.invalid).length > 0;
  }
}
export default Errors;
