<template>
  <input 
    :brcode="code"
    @input="getCode"
  >
  </div>
</template>

<script>
export default {
  name: "BarcodeScanner",
  props: {
    brcode: {
      default: "s",
      required: false,
      type: [String, Number]
    }
  },
  data() {
    return {
      code: null
    };
  },
  watch: {},
  methods: {
    getCode(e) {
      this.$emit("input", e.target.value);
    }
  }
};
</script>

<style>
</style>
