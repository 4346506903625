<template lang="pug">
  .row.m-0.p-0
    
    side-nav(
      :current="0"
      :content="navs"
      @activenav="nav=$event"
    )
    .col-md-10
      .fixed-container
        component(
            :is="nav.component"
            v-bind:details="data"
        )
  
</template>

<script>
export default {
  name: "UserProfile",

  data() {
    return {
      data: null,
      nav: {
        text: "Account",
        icon: "person",
        component: "user-profile-details"
      },
      navs: [
        { text: "Account", icon: "person", component: "user-profile-details" },
        { text: "Payslip", icon: "payment", component: "user-profile-payslip" },
        { text: "Shop", icon: "shopping_cart", component: "user-profile-shop" }
      ]
    };
  }
};
</script>
