export default {
  et() {
    this.tEdit = false;
  },
  c() {
    this.desEdit = false;
  },
  storeUpdate() {
    let title = this.newtitle;
    this.progress = true;
    __m
      ._http("patch", "/_p/" + this.project.id + "/update/title", {
        title: title
      })
      .then(r => {
        location.reload();
        this.progress = false;
      });
  },
  storeDes() {
    let des = this.newDes;
    this.progress = true;
    __m
      ._http("patch", "/_p/" + this.project.id + "/update/description", {
        description: des
      })
      .then(r => {
        location.reload();
        this.progress = false;
      });
  }
};
