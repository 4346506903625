<template>
  <div 
    :id="modal" 
    class="modal fade draggable" 
    tabindex="-1" 
    role="dialog" >
    <div 
      class="modal-dialog modal-lg" 
      role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button 
            type="button" 
            class="close" 
            data-dismiss="modal" 
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col">
              <table class="table  table-sm">
                <thead class="thead-dark" >
                  <tr>
                    <th style="width: 30px">#</th>
                    <th scope="col">Full Name</th>
                    <th scope="col">Hr/Rate</th>
                    <th 
                      scope="col" 
                      class="text-center">Work Hours</th>
                   
                    <template v-if="option">
                      <th scope="col">Options</th>
                    </template>
                  </tr>
                </thead>
                <tbody>
                  <tr 
                    v-for="(t, d) in data" 
                    :key="d">
                    <td>{{ d+1 }} </td>
                    <td scope="row">{{ t.info.firstname }} {{ t.info.lastname }} </td>
                    <td>{{ t.info.rate }} </td>
                    <td class="text-center">{{ t.info.whours }} </td>
                    <template v-if="option">
                      <td><button class="btn btn-outline-success btn-sm">Info</button></td>
                    </template>
                  </tr>
                  <tr >
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="text-center">
                      <strong>{{ total }}</strong><br>
                      Total
                    </td>
                    
                  </tr>  
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button 
            type="button" 
            class="btn btn-secondary" 
            data-dismiss="modal">Close</button>
       
        </div>
      </div>
    </div>
   
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    option: {
      type: Boolean,
      default: false
    },
    modal: {
      required: true,
      default: null,
      type: [String, Array, Number]
    },
    data: {
      type: Array,
      default: null
    },
    title: {
      type: [String, Number],
      default: null
    }
  },
  computed: {
    total() {
      return _.sumBy(this.data, function(e) {
        console.log(e.info.whours);
        return e.info.whours;
      });
    }
  }
};
</script>
