import Vue from "vue";
import "./bootstrap";
/**
 * Global filters
 */
import "./filters";
/**
 * Global directive
 */
import "./directives";
import store from "./storage/storage";

import components from "./components/index";
Vue.use(components);
//import "./components/scanner/index";
Vue.directive("content-focus", {
  inserted: e => {
    e.focus();
  }
});

new Vue({
  el: "#v102",
  store
});

// jquery
$(function() {
  $("[data-toggle='tooltip']").tooltip();
  $("div .dropdown-menu.notifications").click(function(e) {
    e.stopPropagation();
  });
});

// if ("serviceWorker" in navigator) {
//   window.addEventListener("load", () => {
//     navigator.serviceWorker
//       .register("/service-worker.js")
//       .then(registration => {
//         console.log("SW registered: ", registration);
//       })
//       .catch(registrationError => {
//         console.log("SW registration failed: ", registrationError);
//       });
//   });
// }
