<template lang="pug">
  .row.m-0.p-0
    .col-md-2.p-0
        side-nav(
          :current="1"
          :content="a"
          @activenav="nav=$event"
        )
    
    .col-md-10.fixed-container.m-0.p-0.pt-3
      component(
          :is="nav.component"
       )
</template>

<script>
export default {
  name: "ProjectDetails",
  props: {
    project: {
      required: true,
      type: Object,
      default: null
    },
    auth: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      nav: { text: "Details", icon: "info", component: "project-info" },
      navs: [
        { text: "Projects", icon: "work", href: "/_projects/" },
        { text: "Details", icon: "info", component: "project-info" },
        { text: "Boards", icon: "dashboard", component: "board-list" },
        { text: "Settings", icon: "settings", component: "work-settings" }
      ]
    };
  },
  computed: {
    a() {
      if (this.project.own != true) {
        this.navs.splice(-1, 1);
        return this.navs;
      } else {
        return this.navs;
      }
    }
  },

  created() {
    this.$store.dispatch("setProject", this.project);
    this.$store.dispatch("setUser", this.auth);
  }
};
</script>
