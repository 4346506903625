<template>
  <div class="contianer">
    <employee-schedule 
      v-if="modal.modal" 
      :options="modal"></employee-schedule>
    <user-create
      v-if="create"
      @close="create=$event"
    ></user-create>
    <div 
      v-else
      class="col-md-12">
      <div class="mt-3">
        <div class="row">
          <div class="col-10 ">
            <form 
              action="#" 
              class="form-inline col-8" >
              <div class="form-group mr-2">
                <input 
                  v-model="filter.term" 
                  type="text" 
                  class="form-control"
                  placeholder="Search employee."
                >
              </div>
              <div class="form-group">
                <select 
                  id="" 
                  v-model="filter.status" 
                  name=""
                  class="custom-select mr-2"
                >
                  <option 
                    value="Status" 
                    selected>Status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
              <!-- <div class="form-group">
                <select 
                  name="" 
                  id="" 
                  class="custom-select mr-2"
                  v-model="filter.department"
                >
                  <option 
                    value="Department" 
                    selected>Department</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div> -->
              <button 
                class="btn btn-primary"
                @click.prevent="get(filter.current_page, filter.per_page, filter.term)"
              >
                Search
              </button>
            </form>
          </div>
          <div class="col-2 ">
            <button 
              class="btn btn-primary float-right"
              @click="create=true">
              Add
            </button>
          </div>
          <div class="row">
            <nav></nav>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>First name</th>
                    <th>Last name</th>
                    <th class="text-center">Clients</th>
                    <th>Added</th>
                    <th class="text-right">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="progress">
                    <td 
                      class="align-middle"
                      colspan="6"> 
                      <div style="width: 50%; margin: 0 auto; text-align:center">
                        <progress-bar 
                          :status="progress"></progress-bar> 
                        <p class="p-1">loading..</p> 
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-for="(e, q) in filter.data"
                    v-else 
                    :key="q">
                    <td>
                      {{ e.employee_id }}
                    </td>
                    <td class="text-capitalize">
                      <img 
                        src="" 
                        alt="">
                      {{ e.firstname }}
                    </td>
                    <td class="text-capitalize">
                      {{ e.lastname }}
                    </td>
                    <td class="text-center">
                      {{ e.clients.length }}
                    </td>
                    <td>
                      {{ e.created_at | dateFormat('MMM D, Y') }}
                    </td>
                    <td class="text-right">
                      <button 
                        class="btn btn-outline-primary btn-sm"
                        @click="schedule(e)"
                      ><m-icon
                        :options="'md-16'">access_time</m-icon></button>
                      <button class="btn btn-outline-success btn-sm">
                        <m-icon
                          :options="'md-16'">account_box</m-icon>
                      </button>
                      <button class="btn btn-outline-info btn-sm">
                        <m-icon
                          :options="'md-16'">group</m-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="pl-2">
                <paginate 
                  v-if="filter.length != 0 && progress==false"
                  :pagination="filter"
                  :offset="4"
                  @paginate="get(filter.current_page, filter.per_page, filter.term)">
                </paginate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "UsersDashboard",
  data() {
    return {
      create: false,
      modal: {
        modal: null,
        schedule: {
          monday: null,
          tuesday: null,
          wednesday: null,
          thursday: null,
          friday: null,
          saturday: null,
          sunday: null
        }
      },
      filter: {
        total: 0,
        per_page: 24,
        from: 1,
        to: 0,
        current_page: 1,
        data: [],
        term: "",
        status: "1",
        department: "Department"
      },
      progress: false
    };
  },
  beforeMount() {
    this.get(this.filter.current_page, this.filter.per_page, this.filter.term);
  },
  methods: {
    get(current_page, per_page, term) {
      let __this = this;
      __this.progress = true;
      __m
        ._http(
          "post",
          "va?page=" + current_page + "&per_page=" + per_page + "&q=" + term
        )
        .then(e => {
          __this.filter = e.data;
          __this.filter.status = 1;
          __this.filter.department = "department";
          __this.progress = false;
        })
        .catch(e => {
          alert(e);
          __this.progress = false;
        });
    },
    schedule(e) {
      this.modal.modal = e.id;
      this.modal.schedule = e.schedule;
      this.modal.user_id = e.user_id;
    }
  },
};
</script>

<style>
</style>
