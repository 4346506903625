<template>
  <div 
    style="padding: .75rem 1rem" 
    @click="readNotify(details.id)">
    <template v-if="progress">
      <i class="fa fa-spinner fa-spin mr-2"></i>
    </template>
    {{ details.data.content }}
    
  </div>
</template>

<script>
export default {
  name: "NotificationDetails",
  props: {
    notification: {
      default: null,
      required: true,
      type: Object
    }
  },
  data() {
    return {
      details: this.notification,
      progress: false
    };
  },
  methods: {
    readNotify(n) {
      this.progress = true;
      __m
        ._http("post", "/notification/", { id: n })
        .then(r => {
          this.$store.dispatch("removeNotification", n);
          this.progress = false;
        })
        .catch(r => {
          this.progress = false;
        });
    }
  }
};
</script>

<style>
</style>
