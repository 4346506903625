<template>
  <div class="row">
    <div class="col">
      <template v-if="cl_id" >
        <users-list 
          :va="client_va" 
          :client="cl_id" 
          @close='cl_id = $event'></users-list>
      </template>
      
      <template v-else>  
        <div class=" p-3 mb-5 bg-white">
          <div class="form-row">
            <div class="form-group col-sm-3">
              <input 
                type="text" 
                class="form-control" 
                placeholder="Search Client's" 
                aria-label="Search Client's" 
                aria-describedby="Client search"
                v-model="filter.query" 
                @keyup.enter="index(clients.current_page, filter.per_page, filter.query, filter.stat)"
              >  
            </div>
            
            <div class="input-group mb-4 col-sm-3 col-lg-2">
              <select
                class="custom-select" 
                id="status" 
                v-model="filter.stat" 
                @change="index(clients.current_page, filter.per_page, filter.query, filter.stat)" 
              >
                <option selected>Choose...</option>
                <option 
                  class="text-default"
                  value="1">Active</option>
                <option 
                  class="text-alert" 
                  value="0">Inactive</option>
              </select>

              <div class="input-group-append">
                <label 
                  class="input-group-text" 
                  for="status">Status</label>
              </div>

            </div>

            <div class="input-group mb-4 col-sm-3 col-md-3 col-lg-2">
              <select 
                class="custom-select"
                id="result"
                v-model="filter.per_page"
                @change="index(clients.current_page, filter.per_page, filter.query, filter.stat)" >
                <option selected>Choose...</option>
                <option value="12">12</option>
                <option value="16">16</option>
                <option value="26">26</option>
              </select>
              <div class="input-group-append">
                <label 
                  class="input-group-text" 
                  for="result">Result</label>
              </div>
            </div>
          </div>
          <progress-bar :status="progress"></progress-bar>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  
                  <th>Name
                    <span @click="order={name: 'fname', orders: 'desc'}">
                      <span class="material-icons">arrow_drop_down</span>
                    </span>
                    <span @click="order={name: 'fname', orders: 'asc'}">
                      <span class="material-icons">arrow_drop_up</span>
                    </span>
                  </th>
                  <th>
                    Email
                    <span @click="order={name: 'client_email', orders: 'desc'}">
                      <span class="material-icons">arrow_drop_down</span>
                    </span>
                    <span @click="order={name: 'client_email', orders: 'asc'}">
                      <span class="material-icons">arrow_drop_up</span>
                    </span>
                  </th>
                  <th>VA</th>
                  <th>Rate</th>
                  <th>Hours</th>
                  <th>Status</th>
                  <th>Added</th>  
                </tr>
              </thead>
              <tbody>
                <template v-if="progress">
                  <tr 
                    v-for="(client, i) in orderByClient (order)"
                    :key="client.id">
                   
                    <td> {{ client.fname }}</td>
                    <td>{{ client.client_email }}</td>
                    <td><a 
                      href="#" 
                      data-toggle="modal" 
                      data-target="#vamodal"
                      @click.prevent="showAssistant(client.id, client.users)" > {{ client.users.length }}</a>
                    </td>
                    
                    <td>{{ client.rate }}</td>
                    <td>{{ client.hours }}</td>
                    <td>{{ client.status | status }}</td>
                  
                    <td> 
                      <button
                        class="btn btn-outline-primary btn-sm"  
                      >
                        <span class="material-icons md-18 ">assignment</span>
                      </button>
                      <button 
                        type="button"
                        class="btn btn-outline-success btn-sm" 
                        data-toggle="modal" 
                        :data-target="'#edit-'+client.id"
                        @click="edit(client)" 
                      >
                        <span class="material-icons md-18">edit</span>
                      </button>
                      <button
                        class="btn btn-outline-danger btn-sm"  
                      >
                        <span class="material-icons md-18 ">delete</span>
                      </button>
                    </td>
                    <!-- Modal -->
                    <div 
                      class="modal fade" 
                      :id="'edit-'+client.id" 
                      tabindex="-1" 
                      role="dialog" 
                      aria-labelledby="" 
                      aria-hidden="true" 
                      data-backdrop="static">
                      <div 
                        class="modal-dialog modal-lg" 
                        role="document">
                        <div class="modal-content ">
                          <div class="modal-header bg-light">
                            <h5 class="modal-title">Edit Client Info</h5>
                            <button 
                              type="button" 
                              class="close" 
                              data-dismiss="modal" 
                              aria-label="Close"
                              @click="cancelEdit(client)">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div 
                            class="modal-body">
                            <b-alert 
                              v-if="alert.content" 
                              :options="alert">
                            </b-alert>
                            <span 
                              class="font-italic text-danger d-block mb-1" 
                              style="font-size: 12px">All fields are required</span>
                            <form 
                              class="form" 
                              @keydown="errors.clear($event.target.name)"  
                              @change="errors.clear($event.target.name)"  
                              @blur="errors.clear($event.target.name)">
                              <div class="form-row">
                                <div class="form-group col">
                                  <label for="fname">First name</label>
                                  <input 
                                    type="text" 
                                    class="form-control"
                                    :class="( errors.get('firstname') ) ? 'is-invalid' : ''"
                                    v-model="client.firstname" 
                                    name="firstname" 
                                    id="fname">
                                  <span 
                                    v-if="errors.get('firstname')"
                                    v-text="errors.get('firstname')" 
                                    class="text-danger left small"></span>
                                </div>
                                <div class="form-group col">
                                  <label for="lname">Last name</label>
                                  <input 
                                    type="text"
                                    class="form-control"
                                    name="lastname" 
                                    id="lname" 
                                    :class="( errors.get('lastname') ) ? 'is-invalid' : '' " 
                                    v-model="client.lastname" >
                                  <span
                                    class="text-danger left small"
                                    v-if="errors.get('lastname')"
                                    v-text="errors.get('lastname')" 
                                  ></span>
                                </div>
                              </div>

                              <div class="form-row">

                                <div class="form-group col">
                                  <label for="email">Email</label>
                                  <input 
                                    name="client_email" 
                                    type="email" 
                                    class="form-control"
                                    :class="( errors.get('client_email') ) ? 'is-invalid' : '' "
                                    id="email"
                                    v-model="client.client_email">
                                  <span 
                                    class="text-danger left small"
                                    v-if="errors.get('client_email')" 
                                    v-text="errors.get('client_email')" >
                                  </span>
                                </div>


                                <div class="form-group col-md-4 col-lg-3">
                                  <label for="status-edit">Status</label>
                                  <select 
                                    name="status" 
                                    class="form-control custom-select" 
                                    :class="( errors.get('status') ) ? 'is-invalid' : ''" 
                                    id="status-edit" 
                                    v-model="client.status">
                                    <option 
                                      value="client.status"  
                                      selected >{{ client.status | status }}</option>
                                    <option value="ACTIVE-CLIENT">Active</option>
                                    <option value="INACTIVE-CLIENT">Inactive</option>
                                  </select>
                                  <span 
                                    class="text-danger left small"
                                    v-if="errors.get('status')" 
                                    v-text="errors.get('status')"></span>
                                </div>
                              </div>
                                
                              <template v-if="client.status != 'ACTIVE-CLIENT' ">
                                <h5 class="text-danger border-bottom pb-2">Attrition</h5>
                                  
                                <div class="form-row">
                                  <div class="form-group col">
                                    <label for="reasons">Reasons </label>
                                    <input 
                                      type="text" 
                                      class="form-control" 
                                      id="reasons" 
                                      placeholder="Enter client reason" 
                                      v-model="client.reasons"
                                      :class="( errors.get('reasons') ) ? 'is-invalid' : ''" >
                                    <span 
                                      v-if="errors.get('reasons')" 
                                      v-text="errors.get('reasons')" 
                                      class="text-danger left small"></span>
                                  </div>
                                  <div class="form-group col">
                                    <label for="category">Category </label>
                                    <select 
                                      id="category" 
                                      class="custom-select" 
                                      name="category"
                                      v-model="client.type"
                                      :class="( errors.get('type') ) ? 'is-invalid' : ''"
                                    >
                                      <option selected>Choose one</option>
                                      <option 
                                        v-for="(q,s) in attritionOptions.category" 
                                        :key="s" 
                                        :value="q.id">{{ q.content }}</option>
                                    </select>
                                    <span 
                                      v-if="errors.get('type')" 
                                      v-text="errors.get('type')" 
                                      class="text-danger left small"></span>
                                  </div>
                                </div>
                                <div class="form-row">
                                  <table class="table table-sm">
                                    <thead>
                                      <th>Reason</th>
                                      <th>Category</th>
                                      <th>Effectivity</th>
                                    </thead>
                                    <tbody>
                                      <tr 
                                        v-for="(q, s) in client.attrition" 
                                        :key="s">
                                        <td v-text="q.comment"></td>
                                        <td v-text="q.att_category.content"></td>
                                        <td >{{ q.created_at | dateFormat }} </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </template>
                              <div class="form-row mt-2">
                                <div class="form-group col">
                                  <label for="rate">Work Hours</label>
                                  <input 
                                    type="text" 
                                    v-model="client.hours" 
                                    :class="( errors.get('hours') ) ? 'is-invalid' : '' " 
                                    name="hours" 
                                    id="rate" 
                                    class="form-control">
                                  <span 
                                    v-if="errors.get('hours')" 
                                    v-text="errors.get('hours')" 
                                    class="text-danger left small"></span>
                                </div>
                                <div class="form-group col">
                                  <label for="rate">Hourly Rate</label>
                                  <input 
                                    type="text" 
                                    v-model="client.rate" 
                                    :class="( errors.get('rate') ) ? 'is-invalid' : '' " 
                                    name="rate" 
                                    id="rate" 
                                    class="form-control">
                                  <span 
                                    v-if="errors.get('rate')" 
                                    v-text="errors.get('rate')" 
                                    class="text-danger left small"></span>
                                </div>
                              </div>
                         
                            </form>
                          </div>
                        
                          <div class="modal-footer">
                            <button 
                              type="button"
                              data-dismiss="modal" 
                              class="btn btn-secondary" 
                              @click="cancelEdit(client)" 
                            >Close</button>
                            
                            <button 
                              type="button" 
                              class="btn btn-primary" 
                              :disabled="saveProgress" 
                              @click.prevent="saveUpdate(client)">
                              <i 
                                class="fas fa-sync fa-spin" 
                                v-if="saveProgress"></i>
                              Save changes
                            </button>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td 
                      class="align-middle"
                      colspan="6"> 
                      <div style="width: 50%; margin: 0 auto; text-align:center">
                        <progress-bar 
                          :status="progress"></progress-bar> 
                        <p class="p-1">loading..</p> 
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <!-- @paginate="index(clients.current_page, clients.per_page, clients.query, clients.stat)" -->
          <paginate 
            :pagination="clients"
            @paginate="index(clients.current_page, filter.per_page,filter.query, filter.stat)"
            :offset="4">
          </paginate>
          
        </div>
      </template>

    </div>
  </div>
</template>
<script>
import errors from "../../errors";
export default {
  name: "Clients",
  data() {
    return {
      errors: new errors(),
      saveProgress: false,
      client_va: [],
      progress: false,
      clients: {
        total: 0,
        per_page: 12,
        from: 1,
        to: 0,
        current_page: 1,
        data: []
      },
      selected: [],
      allSelected: false,
      order: {
        name: "fname",
        orders: "desc"
      },
      alert: {
        type: "success",
        content: null
      },
      filter: {
        query: "",
        stat: 1,
        per_page: 12
      },
      list: [],
      buttons: [{ name: "", type: "" }],
      modal: {
        status: false,
        data: [],
        title: "Edit Client"
      },
      beforeEdit: {},
      i: [],
      cl_id: null,
      attritionOptions: []
    };
  },
  filters: {
    status(e) {
      return e == "ACTIVE-CLIENT" ? "Active" : "Inactive";
    },
    statusNum(e) {
      return e == "ACTIVE-CLIENT" ? 1 : 0;
    }
  },
  methods: {
    orderByClient(order) {
      return _.orderBy(this.list, order.name, order.orders);
    },
    getAttritionsOptions() {
      __m
        ._http("get", "/att/category")
        .then(e => {
          this.attritionOptions.category = e.data;
        })
        .catch(e => {
          alert(e.data.response);
        });
      __m
        ._http("get", "/att/status")
        .then(e => {
          this.attritionOptions.status = e.data;
        })
        .catch(e => {
          alert(e.data.response);
        });
    },
    edit(e) {
      let __this = this;
      for (const key in e) {
        if (e.hasOwnProperty(key)) {
          const element = e[key];
          __this.beforeEdit[key] = element;
        }
      }
    },
    cancelEdit(e) {
      let __this = this;
      let d = __this.beforeEdit;
      __this.errors.delete();
      for (const key in d) {
        if (d.hasOwnProperty(key)) {
          const element = d[key];
          e[key] = element;
        }
      }
    },
    index(current_page, per_page, query, status) {
      let __this = this;
      __this.progress = true;
      __m
        ._http(
          "get",
          "/clients/filter?page=" +
            current_page +
            "&per_page=" +
            per_page +
            "&q=" +
            query +
            "&s=" +
            status
        )
        .then(e => {
          __this.clients = e.data;
          __this.progress = false;

          Object.values(e.data.data).forEach(e => {
            e.fname = e.firstname + " " + e.lastname;
          });

          __this.list = e.data.data;
        })
        .catch(e => {
          alert(e.response);
          __this.progress = false;
        });
    },
    saveUpdate(e) {
      let __this = this;
      __this.saveProgress = true;
      __m
        ._http("post", "/client/" + e.id + "/update", e)
        .then(e => {
          __this.saveProgress = false;
          __this.alert.status = true;
          __this.alert.content = e.data;
        })
        .catch(e => {
          __this.errors.record(e.response.data["errors"]);
          __this.saveProgress = false;
        });
      this.getAttrition(e);
    },
    showAssistant(e, va) {
      let __this = this;
      __this.cl_id = e;
      __this.client_va = va;
    },
    // get the attrion
    getAttrition(q) {
      q.attrition = [];
      //   __m._http("get", "")
      //   .then((e) => {
      //     this
      //   })
      //   .catch((e) => {

      //   })
      //
    },
    selectAll() {
      let _t = this;
      if (_t.allSelected) {
        Object.values(_t.list).forEach(c => {});
      }
    }
  },
  beforeMount() {
    this.index(
      this.clients.current_page,
      this.clients.per_page,
      this.filter.query,
      this.filter.stat
    );
    this.getAttritionsOptions();
  }
};
</script>

<style>
</style>
