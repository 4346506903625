<template lang="pug">
  i(
    :class="options"
   ).material-icons
    slot
</template>

<script>
export default {
  name: "MIcon",
  props: {
    options: {
      default: null,
      required: false,
      type: String
    }
  }
};
</script>
<style style="scoped">
i.material-icons.hover-red:hover {
  color: rgb(220, 0, 1) !important;
  display: block !important;
}
i {
  cursor: pointer;
}
i.material-icons {
  padding: 2px;
}
i.hover.material-icons:hover {
  background: #eeeeee;
  padding: 2px;
  border-radius: 3px;
}
</style>
