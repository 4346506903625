<template lang="pug">
  .container-fluid
    .row.pt-2.no-gutters
      .col-md-3(
        v-for="(t, k, i) in items"
        :key="i"
      )
        .border-bottom.mb-2.p-0 
            h6.text-capitalize {{ k }} 
        .board-container
            draggable-container(
                :list="t.data"
                :options="options"
                @change="setState($event, k)"
                v-on-scroll
                v-auto-height
            ).board-item
              div(
                  v-for="(e,i) in t.data"
                  :key="i"
                 
              ).rounded.p-2.mb-2.shadow-sm
                progress-bar(
                  :status="progress == e.id"
                )
                .row.m-0.mt-1.rounded

                  .col-md-12.m-0.p-0.py-1.text-truncate
                    span(
                        @click="openWork(e)"
                    ) {{ e.title }}
                  .col-md-12.p-0
                    .col-md-12.p-0
                      template
                        .row.m-0
                          .col.p-0
                            span.fas.fa-clipboard.orange-text.darken-1.mr-2

                            template(
                              v-if="e.due_at"
                            )
                              .col.p-0
                                span.far.fa-clock
                          .col.p-0
                            template(
                              v-if="e.user_id"
                            )
                              user-info(
                                  
                                  :content="e.user_id"
                                  :show-name="false"
                                  :small="true"
                              ).float-right


</template>

<script>
export default {
  name: "Boards",
  data() {
    return {
      items: {},
      source: null,
      progress: null,
      project: this.$store.state.project.id,
      newItems: [],
      options: {
        group: "works",
        type: false
      }
    };
  },
  mounted() {
    this.geItems();
  },
  methods: {
    getStatus(s) {
      if (s == "active") {
        return 1;
      } else if (s == "closed") {
        return 2;
      } else if (s == "new") {
        return 3;
      } else if (s == "resolved") {
        return 4;
      }
    },
    setState(event, k,) {
      let __t = this;
      let data = { state: this.getStatus(k) };
      __t.progress = event.added.element.id;
      __m
        ._http("post", "/_w/" + __t.progress + "/state", data)
        .then(r => {
          this.progress = null;
          e.added.element.state = 3;
        })
        .catch(r => {});
    },

    geItems() {
      this.load = true;
      let c = __m._http("get", "/_b/" + this.project + "/items", {}, true);
      c.p.then(e => {
        this.items = e.data;

        //this.checkNewTask();
      });
    }
  }
};
</script>
    
<style>
</style>
