<template lang="pug">
    a.btn(
        :class="options"
    )
        span(
            :class="icon"
        ).material-icons
            slot(
                name="icons"
            )
        slot 
        
</template>

<script>
export default {
  name: "Button",
  props: {
    options: {
      type: String,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: null
    }
  }
};
</script>
