<template lang="pug">
  .card.shadow-sm.rounded 
    .card-body
        h5.card-title Client
          m-icon(
              :options="'text-muted hover ml-2'"
              @click.native="add=!add"
              v-if="own || a"
          ) add
        .form-group( 
            v-if="add"
            v-click-outside="c"
            )
          client-search(
            v-on:get-data="q=$event"
          )
        .card.border-0
            ul.list-group.list-group-flush
                li(
                    v-for="(e, i) in clients"
                    :key="i"
                ).list-group-item.p-1
                    img(
                        :src="e.avatar | avatar"
                        style="width: 32px"
                    ).img-thumbnail.rounded-circle.img-fluid.border-0
                    strong.ml-2 {{ e.firstname }} {{ e.lastname }}   
                    m-icon(
                      :options="'text-muted hover-red ml-2 float-right md-16'"
                      @click.native="removeclient(e, i)"
                      v-if="own"
                    ) delete          
</template>
<script>
export default {
  name: "ProjectClient",
  props: {
    content: {
      required: true,
      type: null,
      default: null
    }
  },
  
  data() {
    return {
      d: this.$store.state.user,
      clients: this.$store.state.project.clients,
      add: false,
      q: [],
      own: this.$store.state.project.own
    };
  },
  computed:  {
    a() {

        let a = [ 1, 10 ];
        let c = 0;
        for (let index = 0; index < a.length; index++) {
            if( a[index] == this.d.group_id ) {
                c++;
            }
        }
    
        return (c > 0 )  ? true : false 
            
    }
  },
  watch: {
    q() {
      this.addclient();
    }
  },
  methods: {
    c() {
      this.add = false;
    },
    addclient() {
      __m
        ._http(
          "post",
          "/_p/" +
            this.$store.state.project.id +
            "/add/" +
            this.q.id +
            "/client"
        )
        .then(r => {
          if (r.data == true) {
            alert("Could not assign new client. Please remove the old one!");
          } else {
            this.clients.push(this.q);
          }
        });
    },
    removeclient(e, i) {
      if (confirm("Do want remove client?")) {
        __m
          ._http(
            "delete",
            "/_p/" +
              this.$store.state.project.id +
              "/remove/" +
              e.id +
              "/client"
          )
          .then(r => {
            this.$delete(this.clients, i);
          });
      }
    }
  }
};
</script>
