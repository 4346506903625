<template>
  <div>
    <template >
      <div 
        class="form-inline mb-1"
        id="schedule-start">
       
        <div class="form-group">
          <input 
            class="form-control"
            type="number" 
            name="" 
            min="0" 
            max="23"
            v-model="time.startFrom" 
            id="">
        </div>
        :
        <div class="form-group">
          <input 
            class="form-control"
            type="number" 
            name="" 
            min="0" 
            max="59"
            v-model="time.startTo" 
            id="">
        </div>
        -
        <div class="form-group">
          <input 
            class="form-control"
            type="number" 
            name="" 
            min="0" 
            max="23"
            v-model="time.endFrom" 
            id="">
        </div>
        :
        <div class="form-group">
          <input 
            class="form-control"
            type="number" 
            name="" 
            min="0" 
            max="60"
            v-model="time.endTo" 
            id="">
        </div>
        <button 
          class="btn btn-sm btn-primary ml-2" 
          :disabled="progress" 
          @click="store">
          <template v-if="progress">
            <i 
              class="fas fa-sync fa-spin" 
              v-if="progress"></i>
          </template>
          <template v-else>
            <i class="fa fa-save"></i>
          </template>
        </button>
      </div>
    </template>
  </div>

</template>

<script>
export default {
  name: "ScheduleTimepicker",
  props: {
    data: {
      type: Object,
      required: true,
      data: {
        time: null,
        day: null
      }
    }
  },
  data() {
    return {
      time: {
        startFrom: "00",
        startTo: "00",
        endTo: "00",
        endFrom: "00"
      },
      edit: null,
      progress: null
    };
  },
  filters: {
    hours(e) {
      let c = String(e);
      return c.padStart(2, "0");
    }
  },
  watch: {
    schedule(e) {
      // this.store(e)
    }
  },
  computed: {
    schedule() {
      let __t = this.time;
      return (
        __t.startFrom + ":" + __t.startTo + "-" + __t.endFrom + ":" + __t.endTo
      );
    }
  },
  methods: {
    store(e) {
      let __t = this;
      __t.progress = true;
      __m
        ._http("post", "employee/" + this.data.user_id + "/schedule", {
          column: this.data.day,
          time: this.schedule
        })
        .then(e => {
          __t.progress = null;
          console.log(e);
        })
        .catch(e => {
          __t.progress = null;
        });
    }
  }
};
</script>

<style scoped>
</style>
