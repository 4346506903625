<template>
  
  <div class="rounded shadow p-3">
    <div 
      v-if="options"
      class="form-group row justify-content-end mr-2"
    >
      <select 
        id="type" 
        class=" col-md-2  form-control-sm"
        v-model="type"
      >
        <option selected>Month</option>
        <option value="Month">Month</option>
        <option value="Year">Year</option>
      
      </select>
  
     
      <select 
        id="type" 
        class=" col-md-2 form-control-sm"
        v-model="category">
        <option selected>{{ type == 'Month' ? 'Month' : 'Year' }}</option>
        <option 
          v-for="(m,i) in months"
          :key="i"
          :value="m"
          v-text="m"></option>
    
      </select>
    </div>
   

    <chartjs
      v-if="graph"
      :data="this.data"
      :width="400"
      :height="240"
    >
    </chartjs>
  </div>

</template>

<script>
export default {
  name: "LineChart",
  props: {
    options: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    chartData: {
      required: true,
      type: [Object],
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      data: null,
      graph: false,
      months: null,
      category: "--",
      type: "Month"
    };
  },
  computed: {
    years() {
      const year = moment().year();
      return Array.from(
        { length: year + 1 - 2010 },
        (value, index) => 2010 + index
      );
    }
  },

  watch: {
    type() {
      let __this = this;
      if (this.type == "Month") {
        __this.months = moment.monthsShort();
      } else {
        __this.months = this.years;
      }
    },
    category() {
      this.graph = false;
      this.drawChart();
    }
  },
  mounted() {
    this.drawChart();
    this.category = moment().format("M");
    this.months = moment.monthsShort();
  },
  methods: {
    drawChart() {
      let url = this.chartData.url;
      let background = this.chartData.background;
      let label = this.chartData.label;
      let c =
        this.type == "Month"
          ? moment()
              .month(this.category)
              .format("M")
          : this.category;

      let data = "?type=" + this.type + "&category=" + c;

      this.data = axios.get(url + data).then(e => {
        this.graph = true;
        this.data = {
          labels: e.data.labels,
          datasets: [
            {
              label: label,
              backgroundColor: background,
              data: e.data.rows
            }
          ]
        };
      });
    }
  },
};
</script>
