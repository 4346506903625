<template lang="pug">
  .row
    .col-md-12
      .card.border-0.shadow-sm
        .card-body
          b.card-title History
        
          .list-group.list-group-flush
            .list-group-item(
              v-for="(e,i) in histories" 
              :key="i"
            ).p-1
                small {{ e.content }} by &nbsp;
                    user-info(
                        :content="e.user_id"
                        :small="true"
                        :showName="true"
                    )
                    br/
                    span {{ e.created_at | dateFormat("MMM DD, YYYY h:mm:ss a") }}
</template>

<script>
export default {
  name: "WorkHistory",
  props: {
    content: {
      type: Number,
      required: true,
      default: null
    }
  },
  data() {
    return {
      items: []
    };
  },
  computed: {
    histories() {
      return _.orderBy(this.items, "created_at", "desc");
    }
  },
  mounted() {
    this.getItems();
  },
  methods: {
    getItems() {
      __m._http("get", "/_w/" + this.content + "/history").then(e => {
        this.items = e.data;
      });
    }
  },
  beforeDestroy() {
    this.items = null;
  }
};
</script>
