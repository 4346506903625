import Vue from "vue";
import vuex from "vuex";
import Actions from "./actions";
import mutations from "./mutations";

Vue.use(vuex);

const debug = process.env.NODE_ENV !== "production";

export default new vuex.Store({
  state: {
    project: [],
    projects: [],
    archives: [],
    user: {},
    notifications: []
  },
  mutations: mutations,
  actions: Actions,
  strict: debug
});
