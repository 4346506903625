export default {
  countTask(e) {
    let d = 0;
    e.forEach(c => {
      if (c.info["state"] === 2) d++;
    });
    let c = d && e.length ? d + "/" + e.length : 0 + "/" + e.length;
    return e.length != 0 ? c : "";
  },
  createTask() {
    this.create = 1;
    this.open = true;
    this.edited = {
      title: null,
      description: null,
      state: 3,
      labels: [],
      user_id: null,
      type: this.create
    };
    this.options = {
      type: false
    };
  },
  // check task due date
  due_at(e) {
    if (e == null) {
      return null;
    } else if (e != "" || e != null) {
      let c = moment();
      let f = moment(e);

      return f.diff(c) > 0 ? false : true;
    }
  },
  items: _.debounce(function(current_page, per_page) {
    let __t = this;
    __t.progress = true;
    let c = __m._http(
      "get",
      "/_w/" +
        this.project +
        "/s?page=" +
        current_page +
        "&per_page=" +
        per_page +
        "&q=" +
        __t.filter +
        "&t=" +
        __t.trash,
      {},
      true
    );
    this.source = c.source;
    c.p.then(r => {
      __t.works = r.data;
      __t.filterItems = r.data.data;
      __t.progress = false;
      __t.load = false;
    });
  }, 1000),
  openWork(e) {
    let __t = this;
    __t.edited = e;
    __t.open = true;
    __t.options.type = true;
    //   __t.create = e.type;
  }
};
