export default {
  set_project(state, project) {
    state.project = project;
  },
  clear_project(state) {
    state.project = null;
  },
  set_projects(state, d) {
    for (let c of d) {
      if (c.pivot.deleted_at == null) {
        state.projects.push(c);
      } else {
        state.archives.push(c);
      }
    }
  },
  remove_projects(state, d) {
    let index = state.projects.findIndex(e => e.id === d);
    state.projects.splice(index, 1);
  },
  restore_archive(state, d) {
    let index = state.archives.findIndex(e => e.id === d);
    state.archives.splice(index, 1);
  },
  add_projects(state, d) {
    state.projects.push(d);
  },
  clear_projects(state) {
    state.projects = null;
  },
  // store authenticated users
  set_user(state, user) {
    state.user = user;
  },
  // notifications
  set_notification(state, notifications) {
    state.notifications = notifications;
  },
  remove_notification(state, notification) {
    let index = state.notifications.findIndex(e => e.id === notification);
    state.notifications.splice(index, 1);
  },
  remove_notifications(state) {
    state.notifications = [];
  }
};
