<template lang="pug">
  div(
      v-if="content || workType || workId"
    ).p-0
      div.row.m-0.p-0
        div(
            v-on-height="75"
            v-on-scroll
        ).col-md-12.px-1
          .form-row
            .col-md-9
              template(
                v-if="options.type==0"
              )
                h4(
                  class="border-bottom pb-2"
                  :class=" 2 | workColor  "
                ) Task
                  
                  m-icon(
                    :options="'float-right hover text-danger'"
                    @click.native="cc"
                    
                  ) close
              template(
                v-else
              ) 
                h4(
                  class=" border-bottom pb-2"
                  :class="content.type | workColor "
                ) {{ content.type | workType(true) }} 
                   
                    m-icon(
                      :options="'hover text-muted'"
                      @click.native="edited=!edited"
                      v-if="edited==false"
                    ) edit
                    m-icon(
                    :options="'float-right hover text-danger'"
                    @click.native="cc"
                  ) close
              
              form(
                id="form"
                method="post"
                enctype="multipart/form-data"
                v-click-outside="c"
                @keydown="errors.clear($event.target.name)" 
                ) 
                .form-row
                  .form-group.col-md-6.col-sm-6
                      label Title
                      input(
                        type="text"
                        name="title"
                        required
                        placeholder="Enter Title"
                        v-on-readonly="editedWork"
                        v-model="item.title"
                      ).form-control
                      small(
                          v-if="errors.get('title')" 
                      ).small.text-danger.left {{ errors.get('title') }}
                  .form-group.col(
                    v-if="item.type==1"
                  )
                    label Hours
                    input(
                      type="number"
                      name="hours"
                      required
                      placeholder="Hours"
                      v-on-readonly="editedWork"
                      v-model="item.hours"
                    ).form-control

                  .form-group.col
                    label State
                    select(
                      id="state"
                      name="state"
                      v-model="item.state"
                      v-on-readonly="editedWork"
                    ).form-control
                      option(
                        v-for="(e, i) in states"
                        :key="i"
                        :value="e.value"
                      ) {{ e.text }}
                  .form-group.col
                    label Date
                    DatePicker(
                      v-model="item.created_at"
                      clearable
                      :disabled="editedWork"
                      inputClass="form-control"
                    )
                    template(
                      v-if="errors.get('created_at')" 
                    ) 
                    small.small.text-danger.left {{ errors.get('created_at') }}


                  .form-group.col-md-12
                    textarea(
                      placeholder="Add Description"
                      name="description"
                      id="description"
                      v-model="item.description"
                      v-on-readonly="editedWork"
                      style="height: 44px"
                    ).form-control
                  
                  .col-md-12(
                    v-if="edited"
                  ).mb-2
                    button(
                      @click.prevent="saveWork"
                    ).btn.btn-success.btn-sm
                      template(
                        v-if="progress"
                      ) 
                        i.fas.fa-spinner.fa-spin.fa-lg
                      template(
                        v-else
                      ) Save
                    m-icon(
                        @click.native.prevent="edited=false"
                      v-if="options.type == true"
                      :options="'text-muted hover ml-2'"
                    ) close
              
              .row
                .col
                  task-list(
                    :content="item"
                    v-if="content.type===1"
                  )

            .col-md-3.px-2
              ul.nav.nav-pills.mb-2
                li(
                  v-for="(t, e) in s"
                  :key="e"
                  :class="activeNav==e? 'text-primary' : 'text-muted'"
                )
                  m-icon(
                    @click.native="activeNav=e"
                    :options="'hover '"
                  ) {{ t.icon }}
              work-details(
                v-bind:content="item"
                v-if="activeNav==0"
              )
              work-history(
                  v-if="activeNav==1"
                  :content="item.id"
                  v-on-scroll
                  style="height: 80vh"
              )
              template(
                v-if="activeNav==2"
              )
                .card.shadow-sm(
                  v-if="options.type==true"
                )
                  .card-body
                      template(
                          v-if='item.deleted_at'
                      )
                          b.card-title Restore task?
                          p.text-muted Are you sure you want to restore this task?
                          button.btn.btn-sm.btn-warning(
                              @click="r"
                          ) 
                            template(
                                v-if="taskdel"
                              ) 
                                i.fas.fa-spinner.fa-spin.fa-lg
                            template(
                              v-else
                            ) Yes 
                      template(
                          v-else
                      )
                          b.card-title Delete task?
                          p.text-muted Are you sure you want to delete this task?
                          button.btn.btn-sm.btn-danger(
                              @click="d"
                              :disabled="taskdel"
                          ) 
                            template(
                              v-if="taskdel"
                            ) 
                              i.fas.fa-spinner.fa-spin.fa-lg
                            template(
                              v-else
                            ) Yes 
                .card.mt-2
                  .card-body
                    b.card-title WAR
                    p.text-muted Save to WAR?
                    small please select client
                    ul.list-group.list-group-flush.mb-2
                      li(
                        v-for="(c, k) in project_client"
                        :key="k"
                      ).list-group-item.p-1
                        .custom-control.custom-checkbox
                          input(
                            type="checkbox"
                            class="custom-control-input"
                            :id="'c-'+k"
                            v-model="i"
                            :value="c.id"
                          )
                          label(
                            :for="'c-'+k"
                          ).custom-control-label {{ c.firstname }} {{ c.lastname }}
                    
                    button.btn.btn-primary.btn-sm(
                      @click="tow"
                    ) 
                      template(
                        v-if="tra==true"
                      )  
                        i.fas.fa-spinner.fa-spin.fa-lg
                      template(
                        v-if="tra==false"
                      ) Yes 
  
                        
                        

</template>
<script>
import WorkHistory from "./WorkHistory";
import d from "./methods/WorkCreateMethods";
import e from "../../errors";
export default {
  name: "WorkCreate",
  components: { WorkHistory },
  props: {
    itemIndex: {
      required: false,
      type: [Number, Boolean],
      default: null
    },
    content: {
      required: false,
      type: Object,
      default: null
    },
    options: {
      required: true,
      type: Object,
      default: null
    },
    workType: {
      required: false,
      type: [Number],
      default: null
    },

    close: {
      required: false,
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      i: [],
      taskdel: false,
      tra: false,
      errors: new e(),
      project: this.$store.state.project.id,
      project_client: this.$store.state.user.client,

      user_client: this.$store.state.user.client,
      des: null,
      tabs: [
        { text: "Details", icon: "assignment" },
        { text: "History", icon: "restore" },
        { text: "Setting", icon: "settings" }
      ],
      edited: false,
      activeNav: 0,
      loading: false,
      sideProps: null,
      sideName: [],
      desEdited: false,
      states: [
        { text: "New", value: 3 },
        { text: "Active", value: 1 },
        { text: "Resolved", value: 4 },
        { text: "Closed", value: 2 }
      ],
      item: this.content,
      workId: [],
      progress: false,
      own: this.$store.state.project.own
    };
  },
  computed: {
    getState() {
      return this.state;
    },
    header() {
      let __t = this;
      return __t.options.type == 1 ? "Edit" : "New";
    },
    editedWork() {
      if (this.edited == true) {
        return false;
      } else {
        return this.options.type;
      }
    },
    s() {
      if (this.options.type != false) {
        return this.tabs;
      } else {
        return this.tabs;
      }
    }
  },
  mounted() {
    if (this.options.type == false) {
      this.edited = true;
    }
  },
  created() {
    this.sideName = "work-history";
    this.sideProps = this.item.id;
    if (this.options.type) {
      this.workId.id = this.item.id;
    }
    document.onkeydown = evt => {
      evt = evt || window.event;
      if (evt.keyCode == 27) {
        this.cc();
      }
    };
  },
  beforeDestroy() {
    this.loading = true;
  },
  methods: d
};
</script>
