import Vue from "vue";
Vue.directive("on-height", function(e, binding) {
  e.style.height = document.documentElement.clientHeight - binding.value + "px";

  window.addEventListener("resize", function() {
    e.style.height =
      document.documentElement.clientHeight - binding.value + "px";
  });
});

Vue.directive("on-scroll", {
  inserted: e => {
    (e.style.overflow = "hidden"), (e.style.overflowY = "auto");
  }
});
Vue.directive("auto-height", {
  inserted(e, d) {
    let c = isNaN(d.value) ? 160 : d.value;

    e.style.maxHeight = window.innerHeight - c + "px";
    window.addEventListener("resize", function() {
      console.log(c);
      let el = window.innerHeight - c;
      e.style.maxHeight = el + "px";
    });
  }
});

Vue.directive("on-focus", {
  inserted: function(e) {
    e.focus();
  }
});
// focus input
Vue.directive("input-focus", function(e, binding) {
  if (binding.value) {
    e.focus();
  }
});

Vue.directive("on-readonly", function(e, binding) {
  if (binding.value == true) {
    e.setAttribute("disabled", true);
  } else {
    e.removeAttribute("disabled");
  }
});

Vue.directive("click-outside", {
  bind: function(t, e) {
      let n = e.modifiers.bubble,i = function(i) {
          (n || !t.contains(i.target) && t !== i.target) && e.value(i)
      };
      t._clickOutSide = i,
      document.addEventListener("click", i)
  },
  unbind: function(t) {
      document.removeEventListener("click", t._clickOutSide),
      t._clickOutSide = null
  }
});
