<template>
  <div>
    {{ code }}
    <barcode-scanner
      v-model="code"
    ></barcode-scanner>
  </div>
    
</template>

<script>
export default {
  name: "Test",
  data() {
    return {
      code: null
    };
  },
  watch: {
    code: "get"
  },
  methods: {
    get() {
      setTimeout(() => {
        if (this.code != null) {
          this.code = null;
        }
      }, 5000);
    }
  }
};
</script>

<style>
</style>
