<template lang="pug">
.row.m-0.p-0
    .col-md-2.p-0
        side-nav(
          :current="0"
          :content="navs"
          @activenav="nav=$event"
        )
    .col-md-10.fixed-container.m-0.p-0.pt-3
      component(
        :is="nav.component"  
      )
</template>

<script>
export default {
  name: "ProjectIndex",
  props: {
    projects: {
      default: null,
      type: [Object, Array],
      required: true
    },
    user: {
      default: null,
      type: [Array, Object],
      required: true
    }
  },
  data() {
    return {
      nav: { text: "Projects", icon: "list", component: "project-list" },
      navs: [
        { text: "Projetcs", icon: "work", component: "project-list" },
        { text: "Archives", icon: "archive", component: "project-archives" }
      ]
    };
  },
  created() {
    this.$store.dispatch("setProjects", this.projects);
    this.$store.dispatch("setUser", this.user);
  }
};
</script>
