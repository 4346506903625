import Vue from "vue";
import Attrition from "./attrition/Attrition";
import AttritionCategory from "./attrition/Category";
import permission from './settings/controll/permission';

// sub nav
import SubNav from "./resource/SubNavs";
//login
import Login from "./resource/login";
import ProgressBar from "./resource/ProgressBar";
import Edash from "./dashboard";
// mobile navs
import MobileNav from "./resource/MobileNav";
import ClientList from "./resource/ClientList";

// cmponents
import ClientModal from "./clients/edit";
import ClientDashboard from "./clients/ClientDashboard";
import Clients from "./clients/Clients";

// works
import WorkIndex from "./work/WorkIndex";
import WorkCreate from "./work/WorkCreate";
import WorkItems from "./work/WorkItems";
import WorkDetails from "./work/WorkDetails";

// board
import BoardIndex from "./work/boards/BoardIndex";
import BoardList from "./work/boards/BoardList";
import TaskList from "./work/boards/methods/TaskList";
import Boards from "./work/boards/Boards";

// user
import UserInfo from "./user/UserInfo";
import UsersDashboard from "./user/display";
import userCreate from "./user/create";
import EmployeeSchedule from "./user/userSchedule";
import ScheduleTimepicker from "./resource/ScheduleTimepicker";
import UserProfile from "./user/UserProfile";
import UserProfileDetails from "./user/UserProfileDetails";
import UserProfilePayslip from "./user/UserProfilePayslip";
import UserProfileShop from "./user/UserProfileShop";

// support
import Paginate from "./resource/Paginate";
import PaginateMeta from "./resource/PaginateMeta";
import Calendar from "./calendar/calendar";
import modal from "./resource/modal";
import BAlert from "./resource/BAlert";
import DatePicker from "vue-datepicker-local";
import chartjs from "./chart/chart.js";
import LineChart from "./chart/line";
import SideNav from "./resource/SideNav";
import DraggableContainer from "vuedraggable";
import BButton from "./resource/Button";
import ProgressScreen from "./resource/ProgressScreen";
import TaskDone from "./resource/TaskDone";
import MIcon from "./resource/Icon";
import WorkHeader from "./resource/WorkHeader";
import ClientSearch from "./resource/ClientSearch";
import notifications from "./resource/notificationsList";
import notificationCount from "./resource/notificationsCount";
import notificationDetails from "./resource/notificationDetails";

// project
import ProjectIndex from "./work/project/ProjectIndex";
import ProjectList from "./work/project/ProjectList";
import ProjectItem from "./work/project/ProjectItem";
import ProjectInfo from "./work/project/ProjectInfo";
import ProjectDetails from "./work/project/ProjectDetails";
import ProjectClient from "./work/project/components/ProjectClient";
import ProjectMembers from "./work/project/components/ProjectMembers";
import ProjectArchives from "./work/archives/ProjectArchiveList";
import WorkSettings from "./work/WorkSettings";
import WorkUser from "./work/WorkUser";

import barcodeScanner from "./scanner/barcodeScanner";
import indexScanner from "./scanner/indexScanner";

let components = {
  barcodeScanner,
  indexScanner,
  Attrition,
  AttritionCategory,
  BAlert,
  BoardIndex,
  BoardList,
  Boards,
  BButton,
  Calendar,
  chartjs,
  Clients,
  ClientModal,
  ClientDashboard,
  ClientList,

  TaskDone,
  TaskList,
  UsersDashboard,
  EmployeeSchedule,
  ScheduleTimepicker,
  DatePicker,
  DraggableContainer,
  Edash,
  SubNav,
  ProgressBar,
  Login,
  MobileNav,
  PaginateMeta,
  Paginate,
  modal,
  SideNav,
  UserInfo,
  userCreate,
  UserProfile,
  UserProfileDetails,
  UserProfilePayslip,
  UserProfileShop,
  LineChart,
  ProgressScreen,
  ProjectIndex,
  ProjectDetails,
  ProjectClient,
  ProjectMembers,
  ProjectList,
  ProjectItem,
  permission,
  ProjectInfo,
  ProjectArchives,
  WorkIndex,
  WorkCreate,
  WorkDetails,
  WorkItems,
  MIcon,
  WorkHeader,
  WorkSettings,
  WorkUser,
  ClientSearch,
  notifications,
  notificationCount,
  notificationDetails
};

Object.keys(components).forEach(name => {
  Vue.component(name, components[name]);
});

export default components;
