<template lang="pug">
  .row.m-0.p-0
    .col-md-2
        side-nav(
          :content="navs"
          
          @activenav="nav=$event"
        )
    .col-md-10.fixed-container.m-0.p-0
      component(
         :is="nav"
       )
</template>

<script>
export default {
  name: "BoardIndex",
  data() {
    return {
      nav: "board-list",
      navs: [
        { text: "Work Items", icon: "list", component: "work-items" },
        { text: "Boards", icon: "dashboard", component: "board-list" },
        { text: "Backlogs", icon: "settings_backup_restore", component: "" }
      ]
    };
  }
};
</script>
