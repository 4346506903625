<template>
  <div class="container-fluid notification-container">
    <div class="row border-bottom pb-1">
      <div class="col ">
        <b>Notifications</b>
        
      </div>
      <div class="col text-center">
        <a 
          href="#" 
          class="grey-text text-darken-1 mr-3"
          @click="markAllRead()">Mark All as Read</a>
        <a 
          href="/notifications" 
          class="grey-text text-darken-1">See All</a>
      </div>
    </div>
    <div class="row">
      <div class="col px-0">
        <template
          v-if="notification.length">
          <div class="list-group list-group-flush notification-list">
            <a
              v-for="(n, k) in notification"
              :key="k"
              href="#" 
              class="list-group-item list-group-item-action p-0"
             
            >
              <notification-details
                :notification="n">
              </notification-details>
            </a>
          </div>
        </template>
        <template
          v-else
        >
          <p class=" grey-text text-darken-3 text-center my-2">No Unread Notifications</p>
        </template>
        
      </div>
    </div>
    
  </div>
</template>
<script>
export default {
  name: "Notification",
  props: {
    // notifications: {
    //   default: null,
    //   required: true,
    //   type: Array
    // }
  },
  data() {
    return {
      progress: false,
      notification: [],
      newEntriesTimeout: null,
      newEntriesTimer: 5000
    };
  },
  mounted() {
    this.loadNotifications(notifications => {
      this.notification = notifications;
      this.$store.dispatch("setNotifications", notifications);
      this.checkNewNotifications();
    });
  },
  methods: {
    loadNotifications(notifications) {
      __m._http("post", "/notification-count").then(r => {
        if (_.isFunction(notifications)) {
          notifications(r.data);
        }
      });
    },
    checkNewNotifications() {
      this.newEntriesTimeout = setTimeout(() => {
        __m._http("post", "/notification-count").then(r => {
          if (r.data.length != this.notification.length) {
            this.loadNotifications(notifications => {
              this.notification = notifications;
              this.$store.dispatch("setNotifications", notifications);
              this.checkNewNotifications();
            });
          } else {
            this.checkNewNotifications();
          }
        });
      }, this.newEntriesTimer);
    },
    markAllRead() {
      this.progress = true;
      __m
        ._http("post", "/notification/read-all")
        .then(r => {
          this.progress = false;
          this.notification = [];
          this.$store.dispatch("removeNotifications");
        })
        .catch(r => {
          this.progress = false;
          alert(r);
        });
    }
  }
};
</script>

<style scoped>
div.notification-list {
  overflow: hidden;
  overflow-y: scroll;
  max-height: 320px;
}
</style>
