<template>
  <div 
    :id="'em312-'+options.modal" 
    class="modal fade" 
    data-keyboard="false" 
    data-backdrop="static" 
    tabindex="-1" 
    role="dialog" >
    <div 
      class="modal-dialog " 
      role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Schedule</h5>
          <button 
            type="button" 
            class="close" 
            data-dismiss="modal" 
            aria-label="Close"
            @click="options.modal=null"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table 
            class="table table-hover table-sm" 
            style="width: 460px">
            <thead class="border-top-0 ">
              <tr>
                <th>Days</th>
                <th class="text-center">Time</th>
                <th v-if="schededit==false"></th>
              </tr>
            </thead>
                   
            <tbody>
              <tr 
                v-for="(q, e) in days" 
                :key="e">
                <td 
                  class="align-middle"
                  style="text-transform: capitalize" >{{ q }}</td>
                            
                <template v-if="schededit">
                  <td class="text-center"> {{ sched(q)['time'] }}</td>
                </template>
                <template v-else>
                  <!-- <td class="text-center">
                    <div class="form-group">
                      <input 
                        type="text" 
                        name="" 
                        readonly 
                        id="" 
                        class="form-control transparent border-0"
                        :value="sched(q)['time']"
                      >
                    </div>
                  </td> -->
                  <td >
                    <!-- <timePicker v-model="options" :format="'HH:mm'"></timePicker> -->
                    <schedule-timepicker :data="sched(q)"></schedule-timepicker>
                  </td> 
                                
                </template>
                            
              </tr>
            </tbody>
                       
                  
          </table>
        </div>
        <div class="modal-footer">
          <button 
            type="button" 
            class="btn btn-sm btn-secondary" 
            data-dismiss="modal" 
            @click="close">Close</button>
          <button 
            v-if="schededit" 
            :disabled="load"
            type="button" 
            class="btn btn-sm btn-primary" 
            @click="schededit=false">
            <template v-if="load">
              Loading schedule..
            </template>
            <template v-else>
              Update
            </template>

          </button>
          <button 
            v-if="schededit==false" 
            type="button" 
            class="btn btn-sm  btn-success" 
            @click="done">Done</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmployeeSchedule",
  filters: {
    hours(e) {
      let c = String(e);
      return c.padStart(2, "0");
    }
  },
  props: {
    options: {
      required: true,
      default: null,
      type: null
    }
  },

  data() {
    return {
      time: null,
      days: [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday"
      ],
      schededit: true,
      schedule: [],
      load: false
    };
  },
  mounted() {
    this.get();
    $("#em312-" + this.options.modal).modal("show");
  },
  methods: {
    sched(e, d = null) {
      if (_.isEmpty(this.schedule) == false) {
        let data = {
          day: e,
          time: this.schedule[e],
          user_id: this.options.modal
        };
        return data;
      } else {
        return { day: e, time: "--:--  --:--", user_id: this.options.modal };
      }
    },
    done() {
      this.get();
      this.schededit = true;
    },
    close() {
      this.options.modal = null;
    },

    get() {
      let __t = this;
      __t.load = true;
      __m
        ._http("get", "/employee/" + this.options.modal + "/schedule/show")
        .then(e => {
          __t.data = e.data;
          __t.schedule = e.data["schedule"];
          __t.load = false;
        })
        .catch(e => {
          __t.load = false;
        });
    },
    store() {
      __m._http(
        "post",
        "/employee/" + this.options.modal + "/schedule",
        this.newSched
      );
    }
  }
};
</script>
