<template>

  <div>
    <!-- <date-picker v-model="date"   @confirm="confirm" show-buttons  format="YYYY-MM-DD"></date-picker> -->
    <!-- <cal v-model="date" format="DD-MM-YYYY"></cal> -->
    <div class="row">
      <div class="col m3 l3 ">
        <line-chart
         
          :chart-data="chart1"
        ></line-chart>
      </div>
      <div class="col m3 l3 ">
        <line-chart
      
          :chart-data="chart2"
        ></line-chart>
      </div>
    </div>
  

    
  </div>

</template>

<script>
export default {
  data() {
    return {
      date: new Date(),
      chart1: {
        url: "test",
        label: "Employee",
        background: "rgba(02,125,125, .1)",
        category: true
      },
      chart2: {
        url: "c",
        label: "Client",
        background: "rgba(24,91, 190, .6)"
      }
    };
  },
  computed: {},
  methods: {}
  // let now  = moment();
  // this.data.from = now.startOf('month').format('YYYY-MM-DD');
  // this.data.to = now.endOf('month').format('YYYY-MM-DD');
};
</script>
