<script>
import GroupUsers from "./user";
export default {
    name: "SettingsPermissions",
  components: { GroupUsers },
  props: {
        groups: {
            default: null,
            required: true,
            type: Array
        }
    },
  data() {
      return {
        g: this.groups,
        selectedGroup: "",
        created: {
          name: ""
        }
      }
  },
  methods: {
      removeGroup(e) {
        if(e.user.length != 0) {
          alert("Invalid Request.")
        } else {
          if(confirm("Delete group?"))
          {
            __m._http("delete", "permission/" + e.id).then(r =>{
              this.g.splice(e, 1)
              alert("Group deleted");
            })
          }
        }
      },
    clearGroup(e) {
       this.selectedGroup = e;
    },
    addgroup() {
       if(this.created.name!== "") {
          __m._http("post", "permission", this.created).then(r => {
            this.g.unshift(r.data);
            this.created.name = "";
            alert("New group created");
          }).catch(e => {
            alert(e.response.data["message"])
          })
        } else {
          alert("Group name is required");
        }
    }
  }
}
</script>
<template>
  <div class="container-fluid mt-2">
    <div class="row my-4">
      <div class="col-12">
        <form
          class="form-inline "
          action="#"
          @submit.prevent="addgroup">
          <div class="form-group">
            <input
              id="name"
              v-model="created.name"
              aria-placeholder="Enter permission name"
              placeholder="Enter permission name"
              class="form-control"
              type="text"
              name="name">
          </div>
          <button class="btn btn-primary ml-2">Create</button>
        </form>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <group-users
          v-if="selectedGroup"
          :group="selectedGroup"
          @clear-selected-group="clearGroup"
        ></group-users>
      </div>
      <div class="col-12">
        <div class="card-columns ">
          <div
            v-for="(group, i) in g"
            :key="i"
            class="card border-0 shadow">
            <div class="card-header">
              <strong class="text-left"> {{ group.name }}</strong>

              <button
                v-if="group.user.length===0"
                class="btn bg-transparent text-danger float-right  btn-sm"
                title="Delete group"
                @click="removeGroup(group)">
                <span class="fas fa-trash"></span>
              </button>
              <button
                class="btn btn-outline-primary btn-sm float-right"
                @click="selectedGroup=group"
              >Add Employee</button>
            </div>
            <div
              class="card-body"
              style="
                max-height: 210px;
                 min-height: 90px;
                overflow: hidden;
                overflow-y: scroll;
            ">
              <table class="table table-sm">

                <tbody
                >
                  <tr
                    v-for="(user, i) in group.user"
                    :key="i">
                    <td>{{ user.firstname }} {{ user.lastname }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


