<template lang="pug">
span(
    :title="(showName) ? item.name  : ''"
)
  img(
    :src="'http://www.onevirtualsolutions.com/account/avatar/'+item.avatar"
    :style=" small==true ?  'width: 22px;height: 22px' : 'width: 32px;height: 32px' "
    ).rounded-circle.img-fluid.border-0
  strong(
      v-if="showName==true"
  ).ml-2 {{ name }}
</template>

<script>
export default {
  name: "UserInfo",
  props: {
    content: {
      requried: true,
      type: Number,
      default: null
    },
    showName: {
      requried: false,
      type: Boolean,
      default: true
    },
    small: {
      requried: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      item: null,
      members: this.$store.state.project.users
    };
  },
  computed: {
    m() {
      return _.find(this.members, ["id", this.content]);
    },
    name() {
      return this.item.firstname + " " + this.item.lastname;
    }
  },
  beforeMount() {
    this.item = _.find(this.members, ["id", this.content]);
  }
};
</script>

<style>
</style>
