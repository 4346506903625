<template>

  <div class="mb-3 rounded shadow-sm">
    <nav class="nav nav-pills team-nav">
      <li 
        class="nav-item"
        v-for="(i,j) in menus"
        :key="j">
        <a 
          href="#" 
          class="nav-link rounded"  
          @click.prevent="$emit('url', i.data)" 
          :class="(i.data == $parent.url) ? 'active' : ''">
          <i class="material-icons pr-2 ">{{ i.icon }}</i>
          {{ i.name }}
        </a>
      </li> 
    </nav>
  </div>

</template>
<script>
export default {
  name: "SubNavs",
  props: {
    menus: {
      required: true,
      type: [Object, Array]
    }
  },
  methods: {
    getUrl(e) {
      this.$emit("url", e);
    }
  }
};
</script>

<style scoped>
/** 
  navs components
  menus required object
  menus should contain 
  name  String. Number
  icon material icons
  data 
 **/
.team-nav li a {
  color: #555556;
}
.team-nav li a.active {
  color: #000 !important;
  font-weight: bold;
  background-color: rgb(245, 245, 245);
}
.table .members-table thead tr th:nth-child(1),
.table .members-table tbody tr td:nth-child(1) {
  width: 40px;
}
</style>
