export default {
  c() {
    this.desEdited = false;
  },
  r() {
    this.taskdel = true;
    __m
      ._http(
        "patch",
        "/_w/" +
          this.item.pivot.project_id +
          "/task/" +
          this.item.id +
          "/restore/"
      )
      .then(() => {
        this.$delete(
          this.$parent.filterItems,
          _.findIndex(this.$parent.filterItems, ["id", this.item.id])
        );
        this.$parent.open = false;
        this.taskdel = false;
      })
      .catch(() => {
        this.taskdel = false;
      });
  },
  // store task to war
  tow() {
    let _t = this;
    _t.tra = true;
    __m
      ._http(
        "patch",
        "/_p/" + this.project + "/_w/" + this.item.id + "/import",
        {
          c: this.i
        }
      )
      .then(e => {
        _t.tra = false;
        if (e.status == 200) {
          _t.i = [];
          if (e.data) {
            alert("Request done.");
          } else if (e.data == 0) {
            alert("Please choose a client.");
          }
        }
      })
      .catch(e => {
        _t.tra = false;
      });
  },
  d() {
    this.taskdel = true;
    __m
      ._http(
        "delete",
        "/_w/" +
          this.item.pivot.project_id +
          "/task/" +
          this.item.id +
          "/delete/"
      )
      .then(() => {
        this.$delete(
          this.$parent.filterItems,
          _.findIndex(this.$parent.filterItems, ["id", this.item.id])
        );
        this.taskdel = false;
        this.$parent.open = false;
      })
      .catch(() => {
        this.taskdel = false;
      });
  },

  // store new items to database
  store() {
    let __t = this;
    if (__t.options.type == false) {
      __t.progress = true;
      __t.$set(this.content, "type", __t.workType);
      let c = this.content;
      __m
        ._http("post", "/_w/store/" + __t.project + "/work", c)
        .then(e => {
          __t.workId = e.data;
          __t.edited = false;
          __t.item = Object.assign({}, this.item, e.data);
          __t.options.type = true;
          __t.progress = false;
          __t.$emit("update", true);
        })
        .catch(r => {
          __t.progress = false;
          this.errors.record(r.response.data["errors"]);
        });
    }
  },

  saveWork() {
    if (this.options.type == false) {
      this.store();
    } else {
      this.update();
    }
  },
  update() {
    let __t = this;
    __t.progress = true;
    if (__t.own) {
      __m
        ._http(
          "post",
          "/_w/update/" + this.project + "/work/" + this.workId.id,
          this.item
        )
        .then(() => {
          __t.$emit("update", true);
          __t.options.type = true;
          __t.progress = false;
          if (__t.options.type == true) {
            __t.edited = false;
          }
        })
        .catch(() => {
          __t.progress = false;
        });
    }
  },
  cc() {
    // if (confirm("Close Task Item?")) {
    if (this.options.type == false) {
      this.$emit("cancel", false);
    } else {
      this.loading = true;
      this.options.type == false;
      this.loading = false;
      this.$emit("cancel", false);
    }
    // }
  }
};
