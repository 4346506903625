<template lang="pug">
  .row.m-0.p-0
    .col-md-2.p-0
        side-nav(
          :current="1"
          :content="navs"
          @activenav="nav=$event"
        )
    .col-md-10.fixed-container
      component(
          :is="nav.component"
          v-bind:details="data"
       )
</template>

<script>
export default {
  name: "WorkIndex",
  props: {
    project: {
      required: true,
      type: Object,
      default: null
    }
  },
  data() {
    return {
      nav: { text: "Details", icon: "info", component: "project-info" },
      data: this.project,
      navs: [
        { text: "Projects", icon: "work", href: "/_projects/" },
        { text: "Details", icon: "info", component: "project-info" },
        { text: "Boards", icon: "dashboard", component: "board-list" },
        { text: "Settings", icon: "gear", component: "work-settings" }
      ],

    };
  },
  computed: {
    a() {
      if (this.project.own) {
        this.navs.splice(-1, 1);
        return this.navs;
      }
      return this.news;
    }
  }
};
</script>
