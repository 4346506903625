<template>
  <div>
    <h5>Shop</h5>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
