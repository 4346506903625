<template>
  <div>
    <sub-nav 
      :menus="menus" 
      @url="url=$event"></sub-nav>
    <!-- <template v-if="url==='list'">
      <clients></clients>
    </template>
    <template v-else>
      <client-create></client-create>
    </template> -->
    <component :is="url"></component> 
  </div>
</template>

<script>
export default {
  name: "ClientDashboard",
  data() {
    return {
      menus: [
        { name: "list", icon: "account_box", data: "clients" },
        { name: "New", icon: "person_add", data: "client-create" }
      ],
      url: "clients"
    };
  }
};
</script>

<style>
</style>
